import { action, createStore, persist } from "easy-peasy";
import { initialGeneralSettings } from "./utils/helpers";

const store = createStore(
  persist(
    {
      itemMeasurements: [],
      setItemMeasurements: action((state, payload) => {
        state.itemMeasurements = payload;
      }),
      defaultCustomer: {},
      setDefaultCustomer: action((state, payload) => {
        state.defaultCustomer = payload;
      }),
      generalSettings: {
        ...initialGeneralSettings,
      },
      setGeneralSettings: action((state, payload) => {
        state.generalSettings = payload;
      }),
      selectedCompanyForAnalytics: {
        value: "",
        label: "",
        url: "",
      },
      setSelectedCompanyForAnalytics: action((state, payload) => {
        state.selectedCompanyForAnalytics = payload;
      }),
    },
    {
      storage: "localStorage",
    }
  )
);

export default store;
