import { useFormik } from "formik";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { Modal, Form, Nav, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import * as yup from "yup";
import EyeOffOutline from "mdi-react/EyeOffOutlineIcon";
import EyeOutline from "mdi-react/EyeOutlineIcon";

import useDebounce, {
  useBackendUrl,
  useEffectOnce,
  useIsAdmin,
} from "../utils/hooks";
import Avatar from "./utils/Avatar";
import { useAuth } from "../hooks/useAuth";
import { useStoreActions, useStoreState } from "easy-peasy";
import { GearIcon } from "./Icons";
import { useMutation, useQuery } from "react-query";
import { initialGeneralSettings } from "../utils/helpers";
import Select from "react-select";
import CurrencyCustomInput from "./utils/CurrencyCustomInput";
import ExpensesSelector from "./utils/ExpensesSelector";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { Popover } from "react-tiny-popover";
import eventBus from "../utils/EventBus";

export default function GeneralSettingsModal({
  showGeneralSettingsModal,
  setShowGeneralSettingsModal,
}) {
  const { backendUrl } = useAuth();
  const isAdmin = useIsAdmin();
  //const generalSettings = useStoreState((state) => state.generalSettings);
  const setGeneralSettings = useStoreActions(
    (actions) => actions.setGeneralSettings
  );
  const [showJournalPopover, setShowJournalPopover] = useState(false);

  const addSettings = async (payload) => {
    let response = await fetch(`${backendUrl}/api/users/company-settings`, {
      method: "POST",
      // credentials: "include",
      body: JSON.stringify(payload),
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
    });
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };
  const addSettingsMutation = useMutation((payload) => addSettings(payload), {
    onError: () => {
      toast.error(`Unable to perform action`);
    },
  });

  const formik = useFormik({
    initialValues: initialGeneralSettings,
    onSubmit: (values) => {
      //  Save  to  server
      addSettingsMutation.mutate(
        { settings: values },
        {
          onSuccess: ({ message, data }) => {
            setGeneralSettings(values);
            toast.success("Settings saved");
            setShowGeneralSettingsModal(false);
          },
        }
      );
    },
  });

  const setUp = async () => {
    // await waitFor(5000);
    let response = await fetch(`${backendUrl}/api/users/company/settings`, {
      method: "GET",
      headers: {
        Accept: "Application/json",
        "Content-Type": "Application/json",
      },
      credentials: "include",
    });

    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }

    const { data } = await response.json();
    if (data.settings) {
      formik.setValues({ ...initialGeneralSettings, ...data.settings });
    }

    return data;
  };

  const { data, isFetching } = useQuery(
    ["GET_GLOBAL_SETTINGS"],
    () => setUp(),
    {
      enabled: true,
    }
  );

  const handleSelectedJornalAccount = (account) => {
    formik.setFieldValue("drumAccountID", account?.AccountID);
    formik.setFieldValue("drumAccountDescription", account?.Description);
    setShowJournalPopover(false);
  };

  eventBus.useCustomEventListener("JOURNAL_ACCOUNT_CREATED", (account) => {
    handleSelectedJornalAccount(account);
  });

  return (
    <>
      <Modal
        size="lg"
        show={showGeneralSettingsModal}
        onHide={() => setShowGeneralSettingsModal(false)}
        enforceFocus={false}
      >
        <Modal.Header closeButton>
          <Modal.Title className="h5">
            <GearIcon /> Settings
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            noValidate
            onSubmit={formik.handleSubmit}
            autoComplete="off"
            style={isFetching ? { opacity: "0.5", pointerEvents: "none" } : {}}
          >
            <h2 className="mb-3 h5">Personal</h2>
            <Form.Group>
              <Form.Check
                inline
                id="convertToTonsAndPcs"
                label="Convert Quantity to Tons and pcs"
                name="convertToTonsAndPcs"
                type={"checkbox"}
                checked={formik.values.convertToTonsAndPcs}
                onChange={formik.handleChange}
              />
            </Form.Group>

            {isAdmin ? (
              <div className="row border-top my-4 pt-4">
                <h2 className="mb-3 h5">All Users</h2>
                <div className="col-md-6">
                  <Form.Group className="mb-3">
                    <Form.Check
                      inline
                      id="enableCreditLimit"
                      label="Enable Customers Credit Limit"
                      name="enableCreditLimit"
                      type={"checkbox"}
                      checked={formik.values.enableCreditLimit}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Check
                      inline
                      id="enableCreditPaymentForWalkIn"
                      label="Allow Credit Invoice for Walk-In Customer"
                      name="enableCreditPaymentForWalkIn"
                      type={"checkbox"}
                      checked={formik.values.enableCreditPaymentForWalkIn}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Invoice workflow</Form.Label>

                    <div>
                      <Form.Check
                        inline
                        label="Proforma Invoice"
                        value="Proforma Invoice"
                        name="pendingInvoiceType"
                        type={"radio"}
                        id="Proforma Invoice"
                        checked={
                          formik.values.pendingInvoiceType ===
                          "Proforma Invoice"
                        }
                        onChange={formik.handleChange}
                      />
                      <Form.Check
                        inline
                        label="Quotation"
                        value="Quotation"
                        name="pendingInvoiceType"
                        type={"radio"}
                        id="Quotation"
                        checked={
                          formik.values.pendingInvoiceType === "Quotation"
                        }
                        onChange={formik.handleChange}
                      />
                    </div>
                  </Form.Group>

                  {/* <Form.Group className="mb-3">
                  <Form.Label className="mb-1 text-nowrap">Currency</Form.Label>
                  <Select
                    classNamePrefix={"form-select"}
                    isSearchable={false}
                     value={didYouKnowOptions.find(
                      (el) => el.value === formik.values.How_Did_Know
                    )}
                    onChange={({ value }) =>
                      formik.setFieldValue("How_Did_Know", value)
                    }
                    options={didYouKnowOptions}
                  />
                  <Form.Control.Feedback type="invalid" />
                </Form.Group> */}

                  <Form.Group className="mb-3">
                    <Form.Check
                      inline
                      id="hideDiscountColumn"
                      label="Hide Discount Column on Invoice"
                      name="hideDiscountColumn"
                      type={"checkbox"}
                      checked={formik.values.hideDiscountColumn}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Check
                      inline
                      id="addSignatureOnInvoice"
                      label="Add Signature to Invoice"
                      name="addSignatureOnInvoice"
                      type={"checkbox"}
                      checked={formik.values.addSignatureOnInvoice}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Check
                      inline
                      id="addItemDescriptionOnInvoice"
                      label="Show Item Description on Invoice"
                      name="addItemDescriptionOnInvoice"
                      type={"checkbox"}
                      checked={formik.values.addItemDescriptionOnInvoice}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Label>Unit Price Column name on Invoice</Form.Label>
                    <Form.Control
                      id="unitPriceTextOnInvoice"
                      name="unitPriceTextOnInvoice"
                      value={formik.values.unitPriceTextOnInvoice}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Check
                      inline
                      id="linkPaymentToInvoice"
                      label="Link Payment To Invoice"
                      name="linkPaymentToInvoice"
                      type={"checkbox"}
                      checked={formik.values.linkPaymentToInvoice}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <Form.Check
                      inline
                      id="balanceInExpenseFromAccount"
                      label="Expense should be posted from account with balance"
                      name="balanceInExpenseFromAccount"
                      type={"checkbox"}
                      checked={formik.values.balanceInExpenseFromAccount}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>

                  <hr />
                  <h2 className="mb-3 h6">Drums Account</h2>
                  <Form.Group className="mb-3">
                    <Form.Check
                      inline
                      id="enableDrumAccount"
                      label="Enable Drum Account"
                      name="enableDrumAccount"
                      type={"checkbox"}
                      checked={formik.values.enableDrumAccount}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Cost Per Drum</Form.Label>
                    <CurrencyCustomInput
                      id="costPerDrum"
                      name="costPerDrum"
                      value={formik.values.costPerDrum}
                      onValueChange={(value, name) => {
                        formik.setFieldValue(name, value);
                      }}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label>Drum Account</Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        name="account"
                        placeholder="Select Drum Account"
                        value={`${formik.values?.drumAccountID ||
                          "..."} / ${formik.values?.drumAccountDescription ||
                          "..."}`}
                        onChange={() => {}}
                        readOnly
                      />

                      <Popover
                        isOpen={showJournalPopover}
                        onClickOutside={() => setShowJournalPopover(false)}
                        content={() => (
                          <ExpensesSelector
                            handleSelectedExpense={handleSelectedJornalAccount}
                            usage={"chart of accounts"}
                            onHide={() => setShowJournalPopover(false)}
                          />
                        )}
                        position="bottom"
                      >
                        <InputGroup.Text
                          onClick={() => setShowJournalPopover(true)}
                        >
                          <MagnifyIcon />
                        </InputGroup.Text>
                      </Popover>
                    </InputGroup>
                  </Form.Group>
                </div>

                <div className="col-md-6">
                  {" "}
                  <h2 className="mb-3 h6">
                    Create/Update Item (Non Admin user){" "}
                  </h2>
                  <Form.Group className="mb-3">
                    <Form.Check
                      inline
                      id="isPurchasePriceRequired"
                      label="Purchase Price is required"
                      name="isPurchasePriceRequired"
                      type={"checkbox"}
                      checked={formik.values.isPurchasePriceRequired}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Check
                      inline
                      id="presetItemValues"
                      label="Preset Item UnitCost/Price in update item"
                      name="presetItemValues"
                      type={"checkbox"}
                      checked={formik.values.presetItemValues}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Check
                      inline
                      id="presetUnitPrice"
                      label="Preset Unit Price in update item"
                      name="presetUnitPrice"
                      type={"checkbox"}
                      checked={formik.values.presetUnitPrice}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>{" "}
                  <Form.Group className="mb-3">
                    <Form.Check
                      inline
                      id="hasfreightVendor"
                      label="Has Freight Vendor"
                      name="hasfreightVendor"
                      type={"checkbox"}
                      checked={formik.values.hasfreightVendor}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>
                  <hr />
                  <h2 className="mb-3 h6">Post Invoice (Non Admin user)</h2>
                  <Form.Group className="mb-3">
                    <Form.Check
                      inline
                      id="hideUnitCost"
                      label="Hide Unit Cost"
                      name="hideUnitCost"
                      type={"checkbox"}
                      checked={formik.values.hideUnitCost}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Check
                      inline
                      id="hideUnitPrice"
                      label="Hide Unit Price"
                      name="hideUnitPrice"
                      type={"checkbox"}
                      checked={formik.values.hideUnitPrice}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Check
                      inline
                      id="hideDiscount"
                      label="Hide Discount"
                      name="hideDiscount"
                      type={"checkbox"}
                      checked={formik.values.hideDiscount}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Check
                      inline
                      id="presetFinalSellingPrice"
                      label="Preset Final Selling Price"
                      name="presetFinalSellingPrice"
                      type={"checkbox"}
                      checked={formik.values.presetFinalSellingPrice}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>
                  <hr />
                  <h2 className="mb-3 h6">Requires Admin Approval</h2>
                  <Form.Group className="mb-3">
                    <Form.Check
                      inline
                      id="expenseRequiresAdminApproval"
                      label="Expense"
                      name="expenseRequiresAdminApproval"
                      type={"checkbox"}
                      checked={formik.values.expenseRequiresAdminApproval}
                      onChange={formik.handleChange}
                    />
                  </Form.Group>
                  {/*  <Form.Group className="mb-3">
                    <Form.Check
                      inline
                      label="Purchase"
                      name="purchaseRequiresAdminApproval"
                      type={"checkbox"}
                      checked={formik.values.purchaseRequiresAdminApproval}
                      onChange={formik.handleChange}
                    />
                  </Form.Group> */}
                </div>
              </div>
            ) : null}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => formik.submitForm()}
            variant="primary"
            disabled={addSettingsMutation.isLoading}
            className="px-4"
          >
            {addSettingsMutation.isLoading ? "Please wait..." : "Save"}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
