import { Fragment, useState, useEffect } from "react";
import NavDropdown from "react-bootstrap/NavDropdown";
import "../assets/scss/NavBar.scss";
import { HamburgerIcon, HomeIcon, Logo } from "./Icons";
import ChevronDownIcon from "mdi-react/ChevronDownIcon";
import DotsHorizontalIcon from "mdi-react/DotsHorizontalIcon";
import { Dropdown, Image, Modal, Spinner } from "react-bootstrap";
import photoUrl from "./../assets/images/profile.png";
import {
  Link,
  NavLink,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import {
  appSettings,
  backendApis,
  reportsNavItems,
  salesUserReportNavItems,
  storeUserReportNavItems,
  wareHouseChildren,
  vendorManagement,
} from "../config";
import Avatar from "./utils/Avatar";
import {
  useIsAdmin,
  useIsCashier,
  useIsSalesManager,
  useOnClickOutside,
  useToggle,
} from "../utils/hooks";
import { useRef } from "react";
import { useMemo } from "react";
import { toast } from "react-toastify";
import UpdateProfileModal from "./UpdateProfileModal";
import { isEmpty } from "lodash";
import { lowerCase } from "lodash";
import AccountTransferModal from "./SalesAndInvoicing/AccountTransferModal";
import GeneralSettingsModal from "./GeneralSettingsModal";
import ModalLoader from "./utils/ModalLoader";
import { services } from "../config";
import CementModals from "./Cement/CementModals";
import eventBus from "../utils/EventBus";
import { useStoreActions, useStoreState } from "easy-peasy";
import { HRFrontEndUrl, IS_HR, HRBackEndUrl, setCookies } from "../config";

export default function NavBar() {
  const location = useLocation();
  const {
    login,
    logout,
    setUser,
    backendUrl,
    user: authUser,
    isCement,
    isTransportAndLogistics,
    isIronRod,
  } = useAuth();
  const [smShow, setSmShow] = useState(false);
  const [showProfile, setShowProfile] = useState(false);
  const [navOpen, setNavOpen, toggleNavOpen] = useToggle(false);
  const navHolder = useRef();
  const isAdmin = useIsAdmin();
  const isCashier = useIsCashier();
  const isSalesManager = useIsSalesManager();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isAccountTransferOpen, setIsAccountTransferOpen] = useState(false);
  const [showGeneralSettingsModal, setShowGeneralSettingsModal] = useState(
    false
  );
  const generalSettings = useStoreState((state) => state.generalSettings);

  // console.log("authUser => ", authUser);
  // whether to switch company
  const switchCompanyQueryParam = searchParams.get("switchCompany");
  useEffect(() => {
    if (
      switchCompanyQueryParam &&
      authUser.company !== switchCompanyQueryParam
    ) {
      const foundCompany = backendApis.find(
        (el) => el.name === switchCompanyQueryParam
      );
      console.log("foundCompany", foundCompany);
      if (foundCompany) switchCompany(foundCompany);
    }
  }, [switchCompanyQueryParam]);

  const logoutUser = async () => {
    try {
      setSmShow(true);
      const res = await fetch(`${backendUrl}/api/auth/logout`, {
        method: "POST",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        body: JSON.stringify({ Staff_ID: authUser.Staff_ID }),
        credentials: "include",
      });

      if (res.ok) {
        logout();
      } else {
        toast.error("Unable to perform action: Please try again");
      }
    } catch (err) {
      console.log(err);
    } finally {
      setSmShow(false);
    }
  };

  const handleKeyDown = (event) => {
    // event.preventDefault();
    let charCode = String.fromCharCode(event.which).toLowerCase();
    /* if ((event.ctrlKey || event.metaKey) && charCode === "l") {
      alert("CTRL+S Pressed");
    } else if ((event.ctrlKey || event.metaKey) && charCode === "c") {
      alert("CTRL+C Pressed");
    } else if ((event.ctrlKey || event.metaKey) && charCode === "v") {
      alert("CTRL+V Pressed");
    } */

    if (event.ctrlKey || event.metaKey) {
      switch (charCode) {
        case "l":
          logoutUser();
          break;
        default:
      }
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown, true);
    return () => window.removeEventListener("keydown", handleKeyDown, true);
  }, []);

  useEffect(() => {
    setNavOpen(false);
  }, [location]);

  useOnClickOutside(navHolder, () => setNavOpen(false));

  const openAccountTransfer = () => {
    setIsAccountTransferOpen(true);
  };

  const storeUserNav = useMemo(
    () => [
      /*{
        name: "Files",
        childRoutes: [
          {
            name: "Logout",
            shortcut: "Ctrl+L",
            onClick: () => logout(),
          },
        ],
      },*/
      {
        name: "Tools",
        childRoutes: [
          ...(appSettings.isBarrelsAndCrates
            ? [
                {
                  name: "Post Transaction",
                  to: "sales-and-invoicing/create-invoice",
                },
                {
                  name: "Customers",
                  to: "sales-and-invoicing/customer-list",
                },
                {
                  name: "Enquirers",
                  to: "sales-and-invoicing/enquire-list",
                },
              ]
            : []),

          {
            name: "Item Issuer",
            to: "inventory-for-sales/item-issuer",
          },
          /*  {
            name: "Return Invoice Item",
            to: "sales-and-invoicing/return-invoice-item",
          }, */

          {
            name: `Create ${generalSettings.pendingInvoiceType}`,
            to: "sales-and-invoicing/create-proforma-invoice",
          },
          {
            name: "Item Conversion",
            to: "inventory-for-sales/item-conversion",
          },
        ],
      },
      {
        name: "Reports",
        to: "reports",
        childRoutes: [...storeUserReportNavItems],
      },
      {
        name: "View",
        childRoutes: [],
      },
      {
        name: "Shipping",
        childRoutes: [],
      },
      {
        name: "Vendor Management",
        childRoutes: [],
      },
      {
        name: "Forms and Approvals",
        to: "requisition",
        childRoutes: [
          {
            name: "Requisition",
            to: "request",
          },
        ],
      },
      {
        name: "Human Resources",
        HR: true,
      },
    ],
    [authUser?.company, generalSettings.pendingInvoiceType]
  );

  const salesUserNav = useMemo(
    () => [
      /*{
        name: "Files",
        childRoutes: [
          {
            name: "Logout",
            shortcut: "Ctrl+L",
            onClick: () => logout(),
          },
        ],
      },*/
      {
        name: "Tools",
        childRoutes: [
          {
            name: "Post Transaction",
            to: "sales-and-invoicing/create-invoice",
          },
          {
            name: "Customers",
            to: "sales-and-invoicing/customer-list",
          },
          {
            name: `Create ${generalSettings.pendingInvoiceType}`,
            to: "sales-and-invoicing/create-proforma-invoice",
          },
          {
            name: "Supply Center",
            to: "supply-center",
          },
          ...(!appSettings.isBatchStandard
            ? [
                {
                  name: "Post Payments",
                  to: "post-payments",
                },
              ]
            : []),
          ...(isSalesManager
            ? [
                {
                  name: "Approve Pending Invoice",
                  to: "approval",
                },
              ]
            : []),
          {
            name: "Item Conversion",
            to: "inventory-for-sales/item-conversion",
          },
          {
            name: "Delete Invoice",
            to: "sales-and-invoicing/delete-invoice",
          },
        ],
      },
      {
        name: "Reports",
        to: "reports",
        childRoutes: [
          ...salesUserReportNavItems.filter(
            (el) =>
              (isCement ? el.isCementNav : false) ||
              typeof el.isCementNav === "undefined"
          ),
        ],
      },
      {
        name: "Forms and Approvals",
        to: "requisition",
        childRoutes: [
          {
            name: "Requisition",
            to: "request",
          },
        ],
      },
      {
        name: "Human Resources",
        HR: true,
      },
    ],
    [authUser?.company, generalSettings.pendingInvoiceType]
  );

  // Admin && Cashier
  const items = useMemo(
    () => [
      /*{
        name: "Files",
        childRoutes: [
          {
            name: "Logout",
            shortcut: "Ctrl+L",
            onClick: () => logout(),
          },
        ],
      },*/
      {
        name: "Tools",
        childRoutes: [
          ...(isAdmin
            ? [
                {
                  name: "Return Invoice Item",
                  to: "sales-and-invoicing/return-invoice-item",
                },
                {
                  name: "Replace Invoice Item",
                  to: "sales-and-invoicing/replace-invoice-item",
                },
                {
                  name: "Edit Invoice Payment",
                  to: "sales-and-invoicing/replace-payment",
                } /*  {
                  name: "Edit Invoice",
                  to: "sales-and-invoicing/edit-invoice",
                }, */,
                {
                  name: "Delete Invoice",
                  to: "sales-and-invoicing/delete-invoice",
                },
                {
                  name: "Delete Payment",
                  to: "sales-and-invoicing/delete-payment",
                },
                {
                  name: `Create ${generalSettings.pendingInvoiceType}`,
                  to: "sales-and-invoicing/create-proforma-invoice",
                },
                {
                  name: "Credit/Debit Memo",
                  to: "credit-debit-memo",
                },
                {
                  name: "Item Conversion",
                  to: "inventory-for-sales/item-conversion",
                },
                {
                  name: "Refund Payment",
                  to: "refund-payment",
                },
                {
                  name: "Reimbursement",
                  to: "reimbursement",
                },
                {
                  name: "Analytics",
                  to: "analytics",
                },
              ]
            : [
                ...(appSettings?.isMertesacker
                  ? [
                      {
                        name: "Credit/Debit Memo",
                        to: "credit-debit-memo",
                      },
                    ]
                  : []),
                {
                  name: "Return Invoice Item",
                  to: "sales-and-invoicing/return-invoice-item",
                },
                {
                  name: `Create ${generalSettings.pendingInvoiceType}`,
                  to: "sales-and-invoicing/create-proforma-invoice",
                },
              ]),
        ],
      },
      {
        name: "Quick Links",
        childRoutes: [
          {
            name: "Customers",
            to: "sales-and-invoicing/customer-list",
          },

          {
            name: "Sales and Invoicing",
            to: "sales-and-invoicing/invoice-list",
          },
          {
            name: "Make Sales",
            to: "sales-and-invoicing/create-invoice",
          },
          {
            name: "Inventory For Sales",
            to: "inventory-for-sales/inventory-entry",
          },
          {
            name: "Item Issuer",
            to: "inventory-for-sales/item-issuer",
          },
          /*  {
            name: "Warehouse Inventory",
            to: "warehouse-inventory/inventory-list",
          }, */

          {
            name: "Supply Center",
            to: "supply-center",
          },
          ...(isIronRod
            ? [
                ...(isAdmin || isCashier
                  ? [
                      {
                        name: "Approve Pending Invoice",
                        to: "approval",
                      },
                      {
                        name: "Approvals (Expenses)",
                        to: "approval-action",
                      },
                    ]
                  : []),
              ]
            : []),
          ...(isCement
            ? [
                {
                  name: "Raise LPO (Setup ATC)",
                  onClick: () => eventBus.dispatch("showSetupAtcModal", true),
                },
                {
                  name: "Load ATC",
                  to: "load-atc",
                },
                ...(isAdmin
                  ? [
                      {
                        name: "Price Settings",
                        to: "price-settings",
                      },
                      {
                        name: "Create Region",
                        onClick: () =>
                          eventBus.dispatch("showCreateRegionModal", true),
                      },
                      {
                        name: "Create Plant",
                        onClick: () =>
                          eventBus.dispatch("showCreatePlantModal", true),
                      },
                      {
                        name: "Create Depot",
                        onClick: () =>
                          eventBus.dispatch("showCreateDepotModal", true),
                      },
                      {
                        name: "Create Product or Service Category",
                        onClick: () =>
                          eventBus.dispatch("showCreateCategoryModal", true),
                      },
                      {
                        name: "Create Sub Category",
                        onClick: () =>
                          eventBus.dispatch("showCreateSubCategoryModal", true),
                      },
                    ]
                  : []),
                /* {
                  name: "Incentives",
                  to: "incentives",
                }, */
              ]
            : []),
          ...(isTransportAndLogistics
            ? [
                {
                  name: "Driver Salary Center",
                  to: "driver-salary",
                },
              ]
            : []),
          {
            name: "Receive Inventory",
            to: "receive-inventory",
          },
          {
            name: "Expenses Entry",
            to: "expenses-entry",
          },

          isAdmin || !(appSettings.isGigc && isCashier)
            ? {
                name: "Post Payments",
                to: "post-payments",
              }
            : {},
          {
            name: "Reconcilation",
            to: "reconcilation/customer-ledger/Cash",
          },
          {
            name: "Due Invoices",
            to: "sales-and-invoicing/due-invoices",
          },

          generalSettings.enableDrumAccount
            ? {
                name: "Drums Account",
                grandchildRoutes: [
                  {
                    name: "Update Drum Account",
                    to: "drums/update-drum-account",
                  },
                  {
                    name: "Drum Account Sheet",
                    to: "drums/drum-account-sheet",
                  },
                ],
              }
            : {},
        ],
      },
      {
        name: "Maintenance",
        childRoutes: [
          isAdmin
            ? {
                name: "Chart of Account",
                to: "maintenance/chart-of-account",
              }
            : {},
          {
            name: "Journal",
            to: "journal-entry",
            /* grandchildRoutes: [
              {
                name: "Journal Entry",
                to: "journal-entry",
              },
              {
                name: "Expenses Entry",
                to: "expenses-entry",
              },
            ], */
          },
        ],
      },
      {
        name: "Production",
        childRoutes: [
          {
            name: "Plants",
            to: "Production/plants",
          },

          {
            name: "Bill Of Materials",
            to: "production/temlates",
          },
        ],
      },
      {
        name: "Banking",
        childRoutes: [
          {
            name: "Banks",
            to: "banks",
          },
          {
            name: "Bank Account Analysis",
            to: "bank-account-analysis",
          },
          {
            name: "Cash Transfer and Lodgement",
            onClick: () => openAccountTransfer(),
          },
        ],
      },
      {
        name: "Reports",
        to: "reports",
        childRoutes: [...reportsNavItems],
      },
      // isAdmin
      //   ? {
      //       name: "InveX Settings",
      //       childRoutes: [],
      //     }
      //   : {},
      {
        name: "Warehouse",
        to: "warehouse",
        childRoutes: [...wareHouseChildren],
      },
      {
        name: "Vendor Management",
        childRoutes: [
          ...vendorManagement.filter(
            (el) =>
              (isCement ? el.isCementNav : false) ||
              typeof el.isCementNav === "undefined"
          ),
        ],
      },
      {
        name: "Forms and Approvals",
        to: "requisition",
        childRoutes: [
          {
            name: "Requisition",
            to: "request",
          },
        ],
      },

      /* {
        name: <DotsHorizontalIcon style={{ color: "#94A3B8" }} />,
      }, */
    ],
    [
      //  authUser?.company,
      generalSettings.pendingInvoiceType,
      generalSettings.enableDrumAccount,
      isCement,
      isIronRod,
      isCashier,
      isAdmin,
      isTransportAndLogistics,
    ]
  );

  const resolveNav = (department) => {
    department = lowerCase(department);
    return department === "store"
      ? storeUserNav
      : ["sales", "sales manager"].includes(department)
      ? salesUserNav
      : items;
  };

  const switchCompany = async (company) => {
    console.log(company);
    try {
      setSmShow(true);
      // logout old company
      // ---------------------------------------------------------------------
      const res = await fetch(`${backendUrl}/api/auth/logout`, {
        method: "POST",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        body: JSON.stringify({ Staff_ID: authUser.Staff_ID }),
        credentials: "include",
      });

      if (!res.ok) throw new Error();

      // HR Service
      let HRres = null;
      if (IS_HR) {
        HRres = await fetch(`${HRBackEndUrl}/users/switch-company`, {
          method: "POST",
          headers: {
            Accept: "Application/json",
            "Content-Type": "Application/json",
          },
          body: JSON.stringify({
            Staff_ID: authUser.Staff_ID,
            backendApi: company,
          }),
          credentials: "include",
        });

        if (!HRres.ok) throw new Error();
      }

      // login new company
      //-------------------------------------------------------------------------
      const loginResponse = await fetch(`${company.url}/api/auth/authorize`, {
        method: "POST",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
        },
        body: JSON.stringify({ username: authUser.username }),
        credentials: "include",
      });
      if (!loginResponse.ok) {
        logout();
      }

      const { user } = await loginResponse.json();
      if (isEmpty(user)) {
        toast.error("User not found in company: Try again");
        logout();
      }

      // HR Service
      if (IS_HR) {
        const HRData = await HRres.json();
        setCookies(HRData);
      }

      setUser({ ...user, company: company.name });
    } catch (err) {
      console.log(err);
      toast.error("Unable switch company, Please try again");
    } finally {
      setSmShow(false);
    }
  };

  return (
    <nav className="NavBar">
      <div className="d-flex align-items-center">
        <NavLink to={"/dashboard"} className="d-flex align-items-center">
          <div className="home-icon d-none">
            <HomeIcon />
          </div>
          <Logo className="logo" />
        </NavLink>
        <ul ref={navHolder} className={navOpen ? "open" : "closed"}>
          {resolveNav(authUser.Department).map((item, index) => (
            <li key={index}>
              {!item.childRoutes ? (
                item.HR ? (
                  IS_HR ? (
                    <a
                      href={HRFrontEndUrl}
                      className={` ${
                        location.pathname.startsWith(`/${item.to}`)
                          ? "active fw-6"
                          : ""
                      }`}
                    >
                      {item.name}
                    </a>
                  ) : (
                    <Link
                      to="/no-hr"
                      className={` ${
                        location.pathname.startsWith(`/${item.to}`)
                          ? "active fw-6"
                          : ""
                      }`}
                    >
                      {item.name}
                    </Link>
                  )
                ) : (
                  <Link
                    to={item?.to ? item.to : ""}
                    className={` ${
                      location.pathname.startsWith(`/${item.to}`)
                        ? "active fw-6"
                        : ""
                    }`}
                  >
                    {item.name}
                  </Link>
                )
              ) : (
                <Dropdown>
                  <Dropdown.Toggle
                    variant="white"
                    bsPrefix={`toggle ${
                      location.pathname.startsWith(`/${item.to}`)
                        ? "active fw-6"
                        : ""
                    }`}
                  >
                    {item.name}
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    popperConfig={{
                      strategy: "fixed",
                    }}
                    renderOnMount
                  >
                    {item.childRoutes.map((el, index) => (
                      <Fragment key={index}>
                        {el.to ? (
                          <Dropdown.Item as="div">
                            <Link
                              to={`${item.to ? `/${item.to}` : ""}/${el.to}`}
                            >
                              {el.name}
                            </Link>
                          </Dropdown.Item>
                        ) : el.onClick ? (
                          <Dropdown.Item
                            onClick={() => el.onClick()}
                            as="button"
                          >
                            {el.name}{" "}
                            <span className="shortcut">{el?.shortcut}</span>
                          </Dropdown.Item>
                        ) : el.grandchildRoutes ? (
                          <div className="position-relative submenu">
                            <button
                              className="dropdown-item justify-content-between submenu-toggle px-3"
                              href="#"
                            >
                              {el.name} <span>&raquo;</span>
                            </button>
                            {el.grandchildRoutes && (
                              <ul className="dropdown-menu dropdown-submenu text-left">
                                {el.grandchildRoutes.map((grandEl) => (
                                  <li className="w-100" key={grandEl.name}>
                                    <Dropdown.Item as="div">
                                      <Link to={`/${grandEl.to}`}>
                                        {grandEl.name}
                                      </Link>
                                    </Dropdown.Item>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </div>
                        ) : null}
                      </Fragment>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              )}
              {/* <div className="dropdrownchild">
                <div>
                  <p>Excel</p>
                  <p>WordCs</p>
                </div>
              </div> */}
            </li>
          ))}
          {isAdmin ? (
            <li>
              <Dropdown>
                <Dropdown.Toggle variant="white" bsPrefix={`toggle`}>
                  Switch Company
                </Dropdown.Toggle>
                <Dropdown.Menu
                  popperConfig={{
                    strategy: "fixed",
                  }}
                  renderOnMount
                >
                  {backendApis.map((el) => (
                    <Dropdown.Item
                      key={el.name}
                      as="button"
                      className={`${
                        authUser?.company === el.name ? "active" : ""
                      }`}
                      onClick={() => switchCompany(el)}
                    >
                      {el.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </li>
          ) : null}
        </ul>
      </div>
      <div className="d-flex">
        {/*   <ChevronDownIcon style={{ color: "#0A4A95" }} /> */}

        <Dropdown>
          <Dropdown.Toggle
            variant="white"
            className="btn d-flex border-0 align-items-center p-1"
          >
            <Avatar
              className="avatar"
              src={photoUrl}
              name={authUser?.Name}
              company={authUser?.company}
              department={authUser?.Department}
              userId={authUser?.Staff_ID}
            />
          </Dropdown.Toggle>
          <Dropdown.Menu
            popperConfig={{
              strategy: "fixed",
            }}
            renderOnMount
          >
            <Dropdown.Item as="button" onClick={() => setShowProfile(true)}>
              Edit Profile
            </Dropdown.Item>

            {isAdmin && (
              <>
                <Dropdown.Item as="button">
                  <Link to="/dashboard/user-managemnt">User Management</Link>
                </Dropdown.Item>
                <Dropdown.Item as="button">
                  <Link to="/dashboard/company-information-settings">
                    Company Information Settings
                  </Link>
                </Dropdown.Item>
              </>
            )}
            <Dropdown.Item
              as="button"
              onClick={() => setShowGeneralSettingsModal(true)}
            >
              Settings
            </Dropdown.Item>
            <Dropdown.Item as="button">
              {services.frontendUrl === `http://localhost:3000` ? (
                <Link
                  to={`/user-feedBack?UserName=${authUser.Name}&Useremail=${
                    authUser.email
                  }`}
                >
                  FeedBack
                </Link>
              ) : (
                <a
                  href={`https://feedback.invexonline.com/user-feedBack?UserName=${
                    authUser.Name
                  }&Useremail=${authUser.email}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  FeedBack
                </a>
              )}
            </Dropdown.Item>
            <Dropdown.Item as="button" onClick={() => logoutUser()}>
              Logout
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <button onClick={() => toggleNavOpen()} className="btn p-1 d-md-none">
          <HamburgerIcon />
        </button>
      </div>
      <UpdateProfileModal
        show={showProfile}
        onHide={() => setShowProfile(false)}
      />
      {isAccountTransferOpen && (
        <AccountTransferModal
          setShowAccountTransferModal={setIsAccountTransferOpen}
        />
      )}
      {showGeneralSettingsModal && (
        <GeneralSettingsModal
          showGeneralSettingsModal={showGeneralSettingsModal}
          setShowGeneralSettingsModal={setShowGeneralSettingsModal}
        />
      )}
      <ModalLoader show={smShow} />

      {/*  Regions, plants cat, sub cats  */}
      <CementModals />
    </nav>
  );
}
