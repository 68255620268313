import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useState, useRef } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import Datetime from "react-datetime";
import Select from "react-select";
import { Table } from "react-bootstrap";
import { Popover } from "react-tiny-popover";
import { toast } from "react-toastify";
import { useMutation } from "react-query";
import currency from "currency.js";

import { SearchIcon, NoSelectedItemIcon } from "../Icons";
import { CustomInput } from "../utils/CustomInput";
import NumberCustomInput from "../utils/NumberCustomInput";
import CurrencyCustomInput from "../utils/CurrencyCustomInput";
import ItemsTable from "../utils/ItemsTable";
import { useAuth } from "../../hooks/useAuth";
import { backendApis } from "../../config";
import IssueItenModalTable from "../IssueItemModalTable";
import "../../assets/scss/scoped/issueItem.scss";
import ConfirmDialog from "../ConfirmDialogue";
import { fetchActionsUtil } from "../../utils/helpers";
import moment from "moment";
import ModalLoader from "../utils/ModalLoader";
import PrintIssueItemModal from "./PrintIssueItemReceipt";

export const IssueItemModal = (props) => {
  const [carts, setCart] = useState([]);
  const [showItemsPopover, setShowItemsPopover] = useState(false);
  const [allcarts, setAllCart] = useState({});
  const [allstaff, setAllStaff] = useState([]);
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [transid, setTrans_ID] = useState("");

  const { backendUrl, token, user } = useAuth();

  const cashInput = useRef(null);

  const issuedToData = backendApis
    .filter((d) => d.name !== user?.company)
    .map((el) => ({
      value: el.name,
      label: el.name,
    }));

  const formik = useFormik({
    initialValues: {
      issueTo: "",
      itemId: "",
      itemName: "",
      qtyinStock: "",
      issueDate: "",
      qty: "",
      costPrice: "",
      receivingofficer: "",
    },
    validationSchema: yup.object().shape({
      issueTo: yup.string().required("Issue To is required"),
      issueDate: yup.string().required("Issue Date is required"),
      receivingofficer: yup.string().required("Receiving Officer is required"),
      qty: yup.string().required("Quantity is required"),
      itemId: yup.string().required("Item Id is required"),
      costPrice: yup.string().required("costPrice is required"),
    }),
    onSubmit: async (values) => {
      if (parseInt(values.qty) > parseInt(values.qtyinStock)) {
        return toast.error(`Quantity in stock can not be bigger than Quality`);
      }
      const sendTo = backendApis.find((el) => el.name === values.issueTo);

      const data = {
        subtotal: parseInt(values.qty) * parseInt(values.costPrice),
        sendTo: sendTo ? `${sendTo.url}/api/itemissuer/receiver` : "",
        ...values,
        ...allcarts,
      };

      data.Vendor = user?.company;

      const _carts = [...carts];

      const item = _carts.find((d) => d?.Bar_Code === values.itemId);
      const index = _carts.findIndex((d) => d?.Bar_Code === values.itemId);

      // check if the item is already in the cart then replace it else add it new
      if (carts.length > 0 && item?.Bar_Code === values.itemId) {
        const available = {
          ...item,
          qty: values.qty,
          subtotal: parseInt(values.qty) * parseInt(values.costPrice),
        };
        carts.splice(index, 1, available);
      } else {
        setCart((prev) => [...prev, { ...data }]);
      }

      formik.resetForm({
        values: {
          ...values,
          itemId: "",
          itemName: "",
          qtyinStock: "",
          issueDate: "",
          qty: "",
          costPrice: "",
        },
      });
    },
  });

  const handleSelectedItem = (el) => {
    formik.setValues({
      ...formik.values,
      itemId: el.Bar_Code,
      itemName: el.Item_Name,
      qtyinStock: el.quantityInStock,
      costPrice: el.UnitCost,
      issueDate: moment(),
    });
    setAllCart(el);
    setShowItemsPopover(false);
    cashInput.current && cashInput.current.focus();
  };

  const updateQuantity = (num, index) => {
    carts
      .filter((p, i) => i === index)
      .map((e) => {
        e.qty = parseInt(num);
        e.subtotal = parseInt(e.qty) * parseInt(e.costPrice);
        return "";
      });
  };

  const deleteItem = async (i) => {
    const recarts = [...carts];
    const filtered = recarts.filter((re, inx) => inx !== i);
    if (
      await ConfirmDialog({
        title: "Delete Item",
        description: "Are you sure you want to delete this Item from your cart",
      })
    ) {
      setCart(filtered);
    }
  };

  const sendIssueItemMutation = useMutation(
    (payload) =>
      fetchActionsUtil(
        `${backendUrl}/api/itemissuer/create`,
        "POST",
        token,
        payload
      ),
    {
      onSuccess: ({ message, Trans_ID }) => {
        setTrans_ID(Trans_ID);
        setCart([]);
        setAllCart({});
        toast.success(message);
        props.refetchMain();
        setShowPrintModal(true);
      },
      onError: () => {
        toast.error(`Unable to perform action`);
      },
    }
  );

  const getIssueTo = () => {
    let str = "";
    [...new Set(carts.map((d) => d.issueTo))].forEach((e) => {
      str += e + ", ";
    });
    return str.slice(0, -2);
  };

  const sendCarts = async () => {
    if (
      await ConfirmDialog({
        title: "Issue Item",
        description: `Are you sure you want to issue these Items to ${getIssueTo()}`,
      })
    ) {
      // const total = carts.reduce((a, b) => a + b.subtotal, 0);
      const all = { carts };
      console.log(all);
      sendIssueItemMutation.mutate(all);
    }
  };

  const getStaffs = async (name) => {
    const sendTo = `${
      backendApis.find((el) => el.name === name).url
    }/api/users`;
    const staffs = await fetchActionsUtil(sendTo, "GET");
    staffs.staff = staffs.staff.map((el) => ({
      value: el.Name,
      label: el.Name,
    }));
    setAllStaff(staffs.staff);
  };

  return (
    <>
      <Modal
        onHide={props.onHide}
        show={props.show}
        backdropClassName={`global-backdrop`}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="my-style-modal"
        animation={false}
        enforceFocus={false}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div>
              <h1>Issue Item</h1>
            </div>

            <p>Issue item by filling in the following forms.</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="container">
              <div className="row">
                <div className="col-4 border p-2 rounded">
                  <form
                    noValidate
                    onSubmit={formik.handleSubmit}
                    className="form-relative"
                  >
                    <div className="mb-3">
                      <label htmlFor="date" className="form-label">
                        Issue to
                      </label>
                      <Select
                        classNamePrefix={"form-select"}
                        isSearchable={false}
                        value={issuedToData.find(
                          (el) => el.value === formik.values.issueTo
                        )}
                        onChange={({ value }) => {
                          formik.setFieldValue("issueTo", value);
                          getStaffs(value);
                        }}
                        options={issuedToData}
                      />

                      {formik.touched.issueTo && !!formik.errors.issueTo ? (
                        <span className="custom-invalid-feedback">
                          {formik.errors.issueTo}
                        </span>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="date" className="form-label">
                        Receiving Officer
                      </label>
                      <Select
                        classNamePrefix={"form-select"}
                        isSearchable={false}
                        value={allstaff.find(
                          (el) => el.value === formik.values.receivingofficer
                        )}
                        onChange={({ value }) =>
                          formik.setFieldValue("receivingofficer", value)
                        }
                        options={allstaff}
                      />

                      {formik.touched.receivingofficer &&
                      !!formik.errors.receivingofficer ? (
                        <span className="custom-invalid-feedback">
                          {formik.errors.receivingofficer}
                        </span>
                      ) : null}
                    </div>
                    <div className="mb-3 mt-2">
                      <p className="fw-bold">Add Item</p>
                    </div>
                    <div class="mb-3">
                      <label
                        htmlFor="exampleFormControlInput1"
                        class="form-label"
                      >
                        Item Id
                      </label>
                      <div className="input-group mb-3">
                        <Popover
                          isOpen={showItemsPopover}
                          onClickOutside={() => setShowItemsPopover(false)}
                          content={() => (
                            <ItemsTable
                              handleSelectedItem={handleSelectedItem}
                            />
                          )}
                          position="bottom"
                        >
                          <>
                            <CustomInput
                              className="p-cursor"
                              name="itemId"
                              placeholder="Enter Item Id"
                              typeOfInput="text"
                              value={formik.values.itemId}
                              disabled
                              onChange={formik.handleChange}
                              onClick={() =>
                                setShowItemsPopover(!showItemsPopover)
                              }
                            />

                            <span
                              className="input-group-text border p-cursor"
                              id="basic-addon1"
                              onClick={() =>
                                setShowItemsPopover(!showItemsPopover)
                              }
                            >
                              <SearchIcon />
                            </span>
                          </>
                        </Popover>
                      </div>
                      {formik.touched.itemId && !!formik.errors.itemId ? (
                        <span className="custom-invalid-feedback">
                          {formik.errors.itemId}
                        </span>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <CustomInput
                        name="itemName"
                        placeholder="Enter Item Name"
                        typeOfInput="text"
                        label="Item Name"
                        disabled
                        value={formik.values.itemName}
                        onChange={formik.handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="qty" className="form-label">
                        Quantity to issue
                      </label>
                      <NumberCustomInput
                        placeholder="0"
                        name="qty"
                        value={formik.values.qty}
                        onValueChange={(value, name) => {
                          formik.setFieldValue(name, value, true);
                        }}
                        isInvalid={formik.touched.qty && !!formik.errors.qty}
                        ref={cashInput}
                      />
                      {formik.touched.qty && !!formik.errors.qty ? (
                        <span className="custom-invalid-feedback">
                          {formik.errors.qty}
                        </span>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <CustomInput
                        name="qtyinStock"
                        placeholder=""
                        typeOfInput="number"
                        label="QTY in Stock"
                        disabled
                        value={formik.values.qtyinStock}
                        onChange={formik.handleChange}
                      />
                    </div>
                    <div className="mb-3">
                      <label htmlFor="date" className="form-label">
                        Issue Date
                      </label>
                      <Datetime
                        dateFormat="MMM DD, YYYY"
                        timeFormat={false}
                        closeOnSelect={true}
                        closeOnClickOutside={true}
                        name="issueDate"
                        inputProps={{
                          className: `date-input form-control ${
                            formik.touched.issueDate &&
                            !!formik.errors.issueDate
                              ? "is-invalid"
                              : ""
                          }`,

                          placeholder: "Select date",
                          readOnly: true,
                        }}
                        value={formik.values.issueDate}
                        onChange={(date) => {
                          formik.setFieldValue("issueDate", date, true);
                        }}
                        onBlur={() => formik.setFieldTouched("issueDate", true)}
                      />
                      {formik.touched.issueDate && !!formik.errors.issueDate ? (
                        <span className="custom-invalid-feedback">
                          {formik.errors.issueDate}
                        </span>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <label htmlFor="qty" className="form-label">
                        Cost Price
                      </label>
                      <CurrencyCustomInput
                        name="costPrice"
                        value={formik.values.costPrice}
                        onValueChange={(value, name) =>
                          formik.setFieldValue(name, value)
                        }
                        placeholder="0.00"
                        decimalsLimit={2}
                      />
                      {formik.touched.costPrice && !!formik.errors.costPrice ? (
                        <span className="custom-invalid-feedback">
                          {formik.errors.costPrice}
                        </span>
                      ) : null}
                    </div>
                    <div className="add-to-cart-holder d-flex justify-content-end text-primary">
                      <Button
                        type="submit"
                        variant="outline-primary"
                        onClick={formik.handleSubmit}
                      >
                        Add to Cart
                      </Button>
                    </div>
                  </form>
                </div>

                <div className="col-8 pl-4">
                  <div className="px-md-4">
                    <Table
                      responsive
                      borderless
                      striped
                      className="product-table text-nowrap"
                    >
                      <thead>
                        <tr>
                          <th className="p-cursor">Model No.</th>
                          <th className="p-cursor">Item Name</th>
                          <th className="p-cursor">QTY</th>
                          <th className="p-cursor">Cost Price</th>
                          <th className="p-cursor">Sub Total</th>
                        </tr>
                      </thead>
                      {
                        <tbody>
                          {carts.map((el, index) => (
                            <IssueItenModalTable
                              index={index}
                              el={el}
                              deleteItem={deleteItem}
                              updateQuantity={updateQuantity}
                              key={index}
                            />
                          ))}
                        </tbody>
                      }
                    </Table>
                    {carts.length < 1 && (
                      <div className="d-flex justify-content-center">
                        <div className="info text-center">
                          <NoSelectedItemIcon />
                          <h5 className="my-2">No Item In Cart</h5>
                          <p>
                            Please add an item from the form beside, to fill in
                            the <br />
                            cart.
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                  {carts.length > 0 && (
                    <div className="d-flex justify-content-between px-md-4">
                      <div className="gridChild gridChildBorderLeftBlue">
                        <div className="gridChildLeft">
                          <p className="gridChld1">
                            {currency(
                              carts.reduce((a, b) => a + b.subtotal, 0),
                              {
                                symbol: "₦",
                              }
                            ).format()}
                          </p>
                          <p className="gridChld2">Total</p>
                        </div>
                      </div>
                      <div className="mt-3">
                        <Button
                          disabled={sendIssueItemMutation.isLoading}
                          onClick={sendCarts}
                        >
                          {sendIssueItemMutation.isLoading
                            ? "Please wait..."
                            : "Issue Item"}
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <ModalLoader show={sendIssueItemMutation.isLoading} />
        </Modal.Body>
      </Modal>

      {showPrintModal && transid ? (
        <PrintIssueItemModal
          showPrintModal={showPrintModal}
          setShowPrintModal={setShowPrintModal}
          Trans_ID={transid}
        />
      ) : null}
    </>
  );
};
