import React, { Suspense, lazy, useMemo } from "react";
import "./assets/scss/index.scss";
import "react-datepicker/dist/react-datepicker.css";

import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import DashboardLayout from "./components/DashboardLayout";
// import { Test } from "./components/Test";
import InnerPageLayout from "./components/InnerPageLayout";
import { Nav as SalesAndInvoicingNav } from "./components/SalesAndInvoicing/Nav";
import { InvoiceList } from "./components/SalesAndInvoicing/InvoiceList";
import Login from "./components/Login";
import HomeLayout from "./components/HomeLayout";
import MasterLayout from "./components/MasterLayout";
// import Hello from "./components/utils/Hello";
import DetailedIcon, {
  BucketIcon,
  PageHeaderListIcon,
  WareHouseIcon,
} from "./components/Icons";
import Accounts from "./components/Dashboard/Accounts";
// import System from "./components/Dashboard/System";
import { Wrapper as SalesAndInvoicingWrapper } from "./components/SalesAndInvoicing/Wrapper";
import CreateInvoice from "./components/SalesAndInvoicing/CreateInvoice";
import ReportsNav from "./components/Reports/ReportsNav";
// import CashBook from "./components/Reports/CashBook";
import SalesAnalysis from "./components/Reports/SalesAnalysis";
import SalesRepReports from "./components/Reports/SalesRepReports/SalesRepReports";
import Sales from "./components/Reports/SalesRepReports/Sales";
import Nav from "./components/Reports/SalesRepReports/Nav";
import Customers from "./components/Reports/SalesRepReports/Customers";
import SalesByInvoice from "./components/Reports/SalesByInvoice";
import PaymentReport from "./components/Reports/PaymentReport";
import Debtor from "./components/Reports/SalesRepReports/Debtor";
import Payment from "./components/Reports/SalesRepReports/Payment";
import TransactionAnalysis from "./components/Reports/TransactionAnalysis";
import CustomerTransactionAnalysis from "./components/Reports/CustomerTransactionAnalysis";
import CustomerLedger from "./components/Reports/CustomerLedger";
// import LoyaltyCustomers from "./components/Reports/LoyaltyCustomers";
import AccountsPayables from "./components/Reports/AccountsPayables";
import AccountsReceivables from "./components/Reports/AccountsReceivables";
import PurchaseHistory from "./components/Reports/PurchaseHistory";
// import StockPositionByDate from "./components/Reports/StockPositionByDate";
import InventoryAnalysis from "./components/Reports/InventoryAnalysis";
import InventoryAnalysisWarehouse from "./components/Reports/InventoryAnalysisWarehouse";
// import PrintInventoryByProduct from "./components/Reports/PrintInventoryByProduct";
import PostVendorsPayment from "./components/Vendor/PostVendorsPayment";
import VendorsLedger from "./components/Vendor/VendorsLedger";
import Expenses from "./components/Reports/Expenses";
import GeneralLedger from "./components/Reports/GeneralLedger";
import TrialBalance from "./components/Reports/TrialBalance";
import IncomeStatement from "./components/Reports/IncomeStatement";
import BalanceSheet from "./components/Reports/BalanceSheet";
import Send from "./components/Requisition/Send";
import Request from "./components/Requisition/Request";
import RequisitionNav from "./components/Requisition/RequisitionNav";
import RequisitionWrapper from "./components/Requisition/RequisitionWrapper";
import DetailsModal from "./components/Requisition/DetailsModal";
import PreviewModal from "./components/Requisition/PreviewModal";
import ApproveAndSendModal from "./components/Requisition/ApproveAndSendModal";
import ConversationsModal from "./components/Requisition/Conversation/ConversationsModal";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect } from "react";
import { NoMatch } from "./components/utils/NoMatch";
import SalesInventory from "./components/SalesInventory";
import ApprovalIndex from "./components/Approval/ApprovalIndex";
// import ApproveInvoiceModal from "./components/Approval/ApproveInvoiceModal";
import { CustomerList } from "./components/SalesAndInvoicing/CustomerList";
import CreateCustomersInvoice from "./components/SalesAndInvoicing/CreateCustomersInvoice";
import CreateIronRodInvoice from "./components/SalesAndInvoicing/CreateIronRodInvoice";
import { useEffectOnce, useIsAdmin } from "./utils/hooks";
import TransportReport from "./components/Reports/TransportReport";
import TransportRevenueEstimates from "./components/Reports/TransportRevenueEstimates";
import { useAuth } from "./hooks/useAuth";
import DriverSalary from "./components/Salary/DriverSalary";
import Incentive from "./components/Incentives/Incentive";
// import { InventoryList } from "./components/Inventory/InventoryList";
import { InventoryNav } from "./components/Inventory/InventoryNav";
import InventoryListEntry from "./components/Inventory/InventoryListEntry";
import SupplyCenter from "./components/SupplyCenter";
import ReturnItem from "./components/SalesAndInvoicing/ReturnItem";
import PostPayment from "./components/PostPayment";
import CreateProformaInvoice from "./components/SalesAndInvoicing/CreateProformaInvoice";
import { ItemIssuer } from "./components/Inventory/ItemIssuer";
import ReceiveInventory from "./components/Receive/ReceiveInventory";
import DeleteInvoice from "./components/SalesAndInvoicing/DeleteInvoice";
// import ReturnItemFromBranch from "./components/Inventory/ReturnItemFromBranch";
import { InventoryManagerNav } from "./components/Inventory/InventoryManagerNav";
import DamagesReturnRecorder from "./components/Inventory/DamagesReturnRecorder";
import ExpensesEntry from "./components/ExpensesEntry";
import Cookies from "universal-cookie";
import CreditDebitMemo from "./components/creditDebitMemo";
import CreateCreditDebitMemo from "./components/CreateCreditDebitMemo";
import Authorize from "./components/Authorize";
import ChartOfAccount from "./components/Maintenance/ChartOfAccount";
import Account from "./components/Maintenance/Account";
import ChartOfAccountImprest from "./components/Maintenance/ChartOfAccountImprest";
import JournalEntry from "./components/JournalEntry";
import ManageTransaction from "./components/SalesAndInvoicing/ManageTransaction";
import Cashes from "./components/Reports/Cashes";
import EditProformaInvoice from "./components/SalesAndInvoicing/EditProformaInvoice";
import StockRecords from "./components/Reports/StockRecords";
import UserManagemnt from "./components/Dashboard/UserManagement";
import CompanyInformationSettings from "./components/Dashboard/CompanyInformationSettings";
import SubNavs from "./components/SubNavs";
import { wareHouseChildren } from "./config";
import WareHouse from "./components/warehouse/warehouse";
import WarehouseInventoryListEntry from "./components/warehouse/stock/WarehouseInventoryListEntry";
import { WarehouseInventoryNav } from "./components/warehouse/WarehouseInventoryNav";
import StorageLocation from "./components/warehouse/StorageLocation";
import WarehouseChangeSide from "./components/warehouse/WarehouseChangeSide";
import { WarehouseIssuesItem } from "./components/warehouse/WarehouseIssueItem";
import ReceiveItemWarehouse from "./components/warehouse/ReceiveItemWarehouse";
import { WarehouseMaintance } from "./components/warehouse/WarehouseMaintance";
import { VendorsInventoryNav } from "./components/Vendor/VendorInventoryNav";
import Vendors from "./components/Vendor/Vendors";
import VendorCreditDebitMemo from "./components/Vendor/VendorCreditDebitMemo";
import CreateVendorCreditDebitMemo from "./components/Vendor/CreateVendorCreditDebitMemo";
import { VendorWrapper } from "./components/Vendor/VendorWapper";
import ItemConversion from "./components/Inventory/ItemConversion";
import DeletePayment from "./components/SalesAndInvoicing/DeletePayment";
import CreateRefund from "./components/Refund";
import Plants from "./components/Production/Plants";
import { FeedBack } from "./components/FeedBack";
import { ThankYouFeedBack } from "./components/ThankYouFeedBAck";
import PlantChangeSide from "./components/Production/PlantChangeSide";
import { PlantInventoryNav } from "./components/Production/PlantInventryNav";
import ReceiveItemPlant from "./components/Production/ReceiveItemPlant";
import PlantProductLog from "./components/Production/PlantProductLog";
import ItemsProgress from "./components/Production/ItemInProgress";
import PlantTemplateItems from "./components/Production/PlantItemsTemplate";
import PlantItemUsedLog from "./components/Production/PlantItemUsedLog";
import PlantHistoryLog from "./components/Production/PlantHistoryLog";
import ModalLoader from "./components/utils/ModalLoader";

import VendorUpcomingPayments from "./components/Vendor/VendorUpcomingPayments";
import AtcPaymentReport from "./components/Vendor/AtcPaymentReport";
import { ProductWrapper } from "./components/Production/ProductWapper";
import ItemsOutOfStock from "./components/Inventory/ItemsOutOfStock";
import DamagesReturnRecorderWarehouse from "./components/warehouse/DamagesReturnRecorder";
import PlantDamages from "./components/Production/PlantDamages";
import PlantWarehouseDamages from "./components/warehouse/PlantWarehouseDamages";
import SalesIncentive from "./components/Reports/SalesIncentive";
import { EnquireList } from "./components/SalesAndInvoicing/EnquireList";
import ReplaceItem from "./components/SalesAndInvoicing/ReplaceItem";
import Quotation from "./components/SalesAndInvoicing/Quotation";
import Proforma from "./components/SalesAndInvoicing/Proforma";
import { NOHR } from "./components/utils/NOHR";
import ReconcilationNav from "./components/Reconcilation/ReconcilatioNav";
import PostDrumPayment from "./components/PostDrumPayment";
import { DrumsNav } from "./components/Inventory/DrumsNav";
import { lowerCase } from "lodash";

// Lazy imports ------------------------------------------------------
const CreateMeterInvoice = lazy(() =>
  import("./components/SalesAndInvoicing/CreateMeterInvoice")
);

const CreateReimbursement = lazy(() => import("./components/Reimbursement"));

const Banks = lazy(() => import("./components/Banks"));
const CementPriceSettings = lazy(() =>
  import("./components/CementPriceSettings")
);
const BankAccountAnalysis = lazy(() =>
  import("./components/Reports/BankAccountAnalysis")
);
const LoadAtc = lazy(() => import("./components/LoadAtc"));
const Analytics = lazy(() => import("./components/Analytics"));
const CustomerLedgerReconcilation = lazy(() =>
  import("./components/Reconcilation/CustomerLedgerReconcilation")
);
const DueInvoices = lazy(() =>
  import("./components/SalesAndInvoicing/DueInvoices")
);
const EditInvoice = lazy(() =>
  import("./components/SalesAndInvoicing/EditInvoice")
);
const ApprovalAction = lazy(() =>
  import("./components/Approval/ApproveAction")
);
const EditPendingExpense = lazy(() =>
  import("./components/EditPendingExpense")
);
const EditJournalEntry = lazy(() => import("./components/EditJournalEntry"));
const ReplacePayment = lazy(() =>
  import("./components/SalesAndInvoicing/ReplacePayment")
);
// --------------------------------------------------------------------

function App() {
  let location = useLocation();

  const {
    isTransportAndLogistics,
    isIronRod,
    isCement,
    isOilAndGas,
    user: authUser,
  } = useAuth();

  const isAdmin = useMemo(() => lowerCase(authUser?.Department) === "admin", [
    authUser,
  ]);

  let state = location?.state;
  // let url = new URL(document.location.href);

  // Get company from url first, next get from state
  // then set company here before we render our content

  /*  useEffect(() => {
    document.documentElement.style.scrollBehavior = "auto";
    setTimeout(() => window.scrollTo(0, 0), 5);
    setTimeout(
      () => (document.documentElement.style.scrollBehavior = "smooth"),
      5
    );
  }, [location]); */

  /*   useEffectOnce(() => {
    let url = new URL(document.location.href);
    url = searchParamsToObject(url.searchParams.entries());

    // fot gigc
    if (url.authorize && !appSettings.isBatchStandard) {
      if (isEmpty(authUser)) {
      }
    }
  }); */

  useEffectOnce(() => {
    try {
      const cookies = new Cookies();
      const token = cookies.get("x-access-token");
      const token2 = cookies.get("token");
      if (token && authUser?.token) {
        cookies.set("x-access-token", authUser.token, { path: "/" });
      }
      /*  if (token2 && authUser?.token) {
        cookies.set("token", authUser.token, { path: "/" });
      } */
    } catch (err) {
      console.log(err);
    }
  });

  /*   useEffect(() => {
    console.log(location);
  }, [location]); */

  return (
    <Suspense fallback={<ModalLoader show={true} />}>
      <div className="app" key={authUser?.company}>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
        <Routes location={state?.backgroundLocation || location}>
          <Route path="">
            <Route index element={<Navigate to="dashboard" />} />
            <Route element={<HomeLayout />}>
              <Route path="login" element={<Login />} />
            </Route>
            <Route path="authorize" element={<Authorize />} />
            <Route path="user-feedBack" element={<FeedBack />} />
            <Route path="feedback-thankyou" element={<ThankYouFeedBack />} />
          </Route>

          <Route element={<MasterLayout />}>
            <Route path="dashboard" element={<DashboardLayout />}>
              <Route index element={<Dashboard />} />
              <Route path="accounts-dashboard" element={<Accounts />} />
              {/*  <Route path="system-admin-tool" element={<System />} /> */}
              <Route path="user-managemnt" element={<UserManagemnt />} />
              <Route
                path="company-information-settings"
                element={<CompanyInformationSettings />}
              />
            </Route>
            <Route path="sales-inventory" element={<SalesInventory />} />
            <Route path="banks" element={<Banks />} />
            <Route path="analytics" element={<Analytics />} />
            <Route
              path="bank-account-analysis"
              element={<BankAccountAnalysis />}
            />
            <Route path="credit-debit-memo" element={<CreditDebitMemo />} />
            <Route
              path="create-credit-debit-memo"
              element={<CreateCreditDebitMemo />}
            />
            <Route path="reimbursement" element={<CreateReimbursement />} />
            <Route path="refund-payment" element={<CreateRefund />} />
            <Route path="expenses-entry" element={<ExpensesEntry />} />
            <Route path="journal-entry" element={<JournalEntry />} />
            <Route
              path="journal-entry/edit/:TransID"
              element={<EditJournalEntry />}
            />

            {isCement && (
              <>
                <Route path="load-atc" element={<LoadAtc />} />
                {isAdmin && (
                  <Route
                    path="price-settings"
                    element={<CementPriceSettings />}
                  />
                )}
              </>
            )}
            <Route path="incentives" element={<Incentive />} />
            <Route path="driver-salary" element={<DriverSalary />} />
            <Route path="supply-center" element={<SupplyCenter />} />
            <Route path="post-payments" element={<PostPayment />} />
            <Route path="expenses-entry" element={<ExpensesEntry />} />

            <Route basename path="reports">
              <Route
                element={
                  <InnerPageLayout
                    Nav={<ReportsNav />}
                    name="Report Center"
                    description="Your current sales summary and activity"
                    icon={<PageHeaderListIcon />}
                  />
                }
              >
                <Route index element={<Navigate to="payment-report" />} />
                {/*  <Route path="cash-book" element={<CashBook />} /> */}
                <Route path="payment-report" element={<PaymentReport />} />
                <Route path="sale-by-invoice" element={<SalesByInvoice />} />

                <Route
                  path="sales-analysis"
                  element={
                    isTransportAndLogistics ? (
                      <TransportReport />
                    ) : (
                      <SalesAnalysis />
                    )
                  }
                />
                <Route path="sales-incentive" element={<SalesIncentive />} />

                <Route
                  path="sales-rep-reports"
                  element={<SalesRepReports Nav={<Nav />} />}
                >
                  <Route index element={<Navigate to="sales" />} />
                  <Route index path="sales" element={<Sales />} />
                  <Route index path="customers" element={<Customers />} />
                  <Route index path="payment" element={<Payment />} />
                  <Route index path="debtors" element={<Debtor />} />
                </Route>

                <Route
                  path="transaction-analysis"
                  element={<TransactionAnalysis />}
                />
                <Route
                  path="customer-transactions-analysis"
                  element={<CustomerTransactionAnalysis />}
                />
                <Route
                  path="customer-ledger"
                  element={<CustomerLedger key={location.pathname} />}
                />
                {/*  <Route
                  path="loyalty-customers"
                  element={<LoyaltyCustomers />}
                /> */}
                <Route
                  path="accounts-recievables"
                  element={<AccountsReceivables />}
                />
                <Route
                  path="accounts-payables"
                  element={<AccountsPayables />}
                />
                <Route path="purchase-history" element={<PurchaseHistory />} />
                {/* <Route
                  path="stock-position-by-date"
                  element={<StockPositionByDate />}
                /> */}
                <Route
                  path="inventory-analysis"
                  element={<InventoryAnalysis />}
                />
                <Route path="stock-records" element={<StockRecords />} />
                <Route
                  path="inventory-analysis-warehouse"
                  element={<InventoryAnalysisWarehouse />}
                />
                {/*  <Route
                  path="print-inventory-by-product"
                  element={<PrintInventoryByProduct />}
                /> */}
                <Route path="expenses" element={<Expenses />} />
                <Route
                  path="imprest-account"
                  element={<ChartOfAccountImprest />}
                />
                {/* <Route
                  path="bank-account-analysis"
                  element={<BankAccountAnalysis />}
                /> */}
                <Route path="general-ledger" element={<GeneralLedger />} />
                <Route path="trial-balance" element={<TrialBalance />} />
                <Route path="income-statement" element={<IncomeStatement />} />
                <Route path="balance-sheet" element={<BalanceSheet />} />

                <Route path="transport" element={<TransportReport />} />
                <Route
                  path="transport-revenue-estimates"
                  element={<TransportRevenueEstimates />}
                />

                <Route path="cash-bank-balances" element={<Cashes />} />
              </Route>
            </Route>

            {/*  */}
            <Route basename path="warehouse">
              <Route
                element={
                  <InnerPageLayout
                    Nav={<SubNavs childrenArrayNav={wareHouseChildren} />}
                    name="Warehouse"
                    description="Your warehouse deatials and activities"
                    icon={<WareHouseIcon />}
                  />
                }
              >
                {/* <Route index element={<Navigate to="/" />} /> */}
                <Route index path="warehouse" element={<WareHouse />} />
                <Route
                  index
                  path="storage-location"
                  element={<StorageLocation />}
                />
              </Route>
            </Route>
            {/*  */}

            <Route basename path="warehouse-inventory">
              <Route
                element={
                  <InnerPageLayout
                    name="Warehouse Inventory"
                    description="Your warehouse detials and activities"
                    icon={<WareHouseIcon />}
                    sideComponent={<WarehouseChangeSide />}
                  />
                }
              >
                <Route
                  element={
                    <SalesAndInvoicingWrapper Nav={<WarehouseInventoryNav />} />
                  }
                >
                  {/* <Route index element={<Navigate to="/inventry-entry" />} /> */}
                  <Route
                    path="inventry-entry"
                    element={<WarehouseInventoryListEntry />}
                  />
                  <Route path="item-issuer" element={<WarehouseIssuesItem />} />
                  <Route
                    path="receive-item"
                    element={<ReceiveItemWarehouse />}
                  />
                  <Route path="maintance" element={<WarehouseMaintance />} />
                  <Route
                    path="plant-damages"
                    element={<PlantWarehouseDamages />}
                  />

                  <Route
                    path="damages-return-recorder"
                    element={<DamagesReturnRecorderWarehouse />}
                  />
                </Route>
              </Route>
            </Route>

            {/*  */}

            <Route basename path="vendor">
              <Route
                element={
                  <InnerPageLayout
                    name="Vendors List"
                    description="Your vendor detials and activities"
                    icon={<PageHeaderListIcon />}
                  />
                }
              >
                <Route
                  element={<VendorWrapper Nav={<VendorsInventoryNav />} />}
                >
                  <Route path="vendors" element={<Vendors />} />
                  <Route path="vendors-ledger" element={<VendorsLedger />} />
                  <Route
                    path="post-vendors-payment"
                    element={<PostVendorsPayment />}
                  />
                  <Route
                    path="creadit-debit-memo"
                    element={<VendorCreditDebitMemo />}
                  />
                  <Route
                    path="create-vendors-memo"
                    element={<CreateVendorCreditDebitMemo />}
                  />
                  <Route
                    path="upcoming-payments"
                    element={<VendorUpcomingPayments />}
                  />
                  <Route
                    path="atc-payment-report"
                    element={<AtcPaymentReport />}
                  />
                </Route>
              </Route>
            </Route>
            {/*  */}

            <Route basename path="Production">
              {/* <Route
              element={
                <InnerPageLayout
                  name="Production List"
                  description="Your production detials and activities"
                  icon={<PageHeaderListIcon />}
                />
              }
            > */}
              <Route path="plants" element={<Plants />} />
              <Route path="temlates" element={<PlantTemplateItems />} />
              {/* </Route> */}
            </Route>

            {/*  */}
            <Route basename path="plant-inventory">
              <Route
                element={
                  <InnerPageLayout
                    name="Plant Inventory"
                    description="Your plant detials and activities"
                    icon={<WareHouseIcon />}
                    sideComponent={<PlantChangeSide />}
                  />
                }
              >
                <Route element={<ProductWrapper Nav={<PlantInventoryNav />} />}>
                  <Route path="inventry-entry" element={<PlantProductLog />} />
                  <Route path="item-progress" element={<ItemsProgress />} />
                  <Route path="receive-item" element={<ReceiveItemPlant />} />
                  <Route path="item-used" element={<PlantItemUsedLog />} />
                  <Route path="plant-history" element={<PlantHistoryLog />} />
                  <Route
                    path="bill-of-quantity"
                    element={<PlantTemplateItems hideInsideHeader={true} />}
                  />
                  <Route path="plant-damages" element={<PlantDamages />} />
                </Route>
              </Route>
            </Route>

            {/*  */}
            <Route path="sales-and-invoicing">
              <Route
                element={
                  <InnerPageLayout
                    name="Sales Center"
                    description="Manage invoice and create a new invoice."
                    icon={<PageHeaderListIcon />}
                  />
                }
              >
                <Route
                  element={
                    <SalesAndInvoicingWrapper Nav={<SalesAndInvoicingNav />} />
                  }
                >
                  <Route index element={<Navigate to="invoice-list" />} />
                  <Route path="invoice-list" element={<InvoiceList />} />
                  <Route path="customer-list" element={<CustomerList />} />
                  <Route path="inventory" element={<InventoryListEntry />} />
                  <Route
                    path="manage-transaction"
                    element={<ManageTransaction />}
                  />
                  <Route path="enquire-list" element={<EnquireList />} />
                  <Route path="quotation" element={<Quotation />} />
                  <Route path="proforma" element={<Proforma />} />
                  <Route path="due-invoices" element={<DueInvoices />} />
                </Route>
              </Route>
              {/*  */}

              <Route
                path="create-invoice"
                element={
                  isIronRod ? (
                    <CreateIronRodInvoice />
                  ) : isCement ? (
                    <CreateCustomersInvoice />
                  ) : isOilAndGas ? (
                    <CreateMeterInvoice />
                  ) : (
                    <CreateInvoice />
                  )
                }
              />

              <Route
                path="create-proforma-invoice"
                element={<CreateProformaInvoice />}
              />
              <Route
                path="edit-proforma-invoice/:TransactionID"
                element={<EditProformaInvoice />}
              />

              {/* Create invoices  */}
              <Route
                path="create-customers-invoice"
                element={<CreateCustomersInvoice />}
              />
              <Route
                path="create-meter-invoice"
                element={<CreateMeterInvoice />}
              />
              <Route
                path="create-iron-rod-invoice"
                element={<CreateIronRodInvoice />}
              />
              {/*  */}
              <Route path="return-invoice-item" element={<ReturnItem />} />
              <Route path="replace-invoice-item" element={<ReplaceItem />} />
              <Route path="delete-invoice" element={<DeleteInvoice />} />
              <Route path="delete-payment" element={<DeletePayment />} />
              <Route path="edit-invoice" element={<EditInvoice />} />
              <Route path="replace-payment" element={<ReplacePayment />} />
            </Route>

            <Route
              path="requisition"
              element={
                <InnerPageLayout
                  name="Requisition Service"
                  description="your submission activity and request acceptance"
                  icon={<PageHeaderListIcon />}
                />
              }
            >
              <Route element={<RequisitionWrapper Nav={<RequisitionNav />} />}>
                <Route index element={<Navigate to="request" />} />
                <Route path="send" element={<Send />} />
                <Route exact path="request" element={<Request />}>
                  <Route path="details/:id" element={<DetailsModal />} />
                  <Route path="preview/:id" element={<PreviewModal />} />
                  <Route
                    path="approve-and-send/:id"
                    element={<ApproveAndSendModal />}
                  />

                  {/* Rerender WHEN id CHANGES */}
                  <Route
                    path="conversations/:id"
                    render={(props) => (
                      <ConversationsModal
                        key={props.match.params.id}
                        {...props}
                      />
                    )}
                  />
                </Route>
              </Route>
            </Route>

            <Route exact path="approval" element={<ApprovalIndex />} />
            <Route exact path="approval-action" element={<ApprovalAction />} />

            <Route path="approval/expense" element={<EditPendingExpense />} />
            <Route
              path="approval/details/:TransactionID"
              element={<EditProformaInvoice />}
            />

            <Route basename path="inventory-for-sales">
              <Route
                element={
                  <InnerPageLayout
                    name="Inventory for Sales"
                    description="Manage and organize your inventory"
                    icon={<PageHeaderListIcon />}
                  />
                }
              >
                <Route
                  element={<SalesAndInvoicingWrapper Nav={<InventoryNav />} />}
                >
                  <Route index element={<Navigate to="inventory-entry" />} />
                  <Route
                    path="inventory-entry"
                    element={<InventoryListEntry />}
                  />
                  <Route path="item-conversion" element={<ItemConversion />} />
                  <Route path="vendor" element={<Vendors />} />
                  <Route path="item-issuer" element={<ItemIssuer />} />
                  <Route
                    exact
                    path="inventory-manager"
                    element={<InnerPageLayout Nav={<InventoryManagerNav />} />}
                  >
                    <Route
                      index
                      element={<Navigate to="damages-return-recorder" />}
                    />
                    {/*  <Route
                    path="return-item-from-branch"
                    element={<ReturnItemFromBranch />}
                  /> */}
                    <Route
                      path="damages-return-recorder"
                      element={<DamagesReturnRecorder />}
                    />
                  </Route>
                  <Route
                    path="manage-transaction"
                    element={<ManageTransaction />}
                  />
                  <Route
                    path="items-out-of-stock"
                    element={<ItemsOutOfStock />}
                  />
                </Route>
              </Route>
            </Route>

            {/* <Route basename path="receive-inventory">
            <Route
              element={
                <InnerPageLayout
                  name="Recieve Inventory"
                  description="Receive all your inventory"
                  icon={<PageHeaderListIcon />}
                />
              }
            >
              <Route
                element={<SalesAndInvoicingWrapper Nav={<InventoryNav />} />}
              >
                <Route index element={<Navigate to="receiving-inventory" />} />
                <Route
                  path="inventory-entry"
                  element={<InventoryListEntry />}
                /> <Route path="item-issuer" element={<ItemIssuer />} />
              </Route>
            </Route>
          </Route> */}
            <Route
              exact
              path="receive-inventory"
              element={<ReceiveInventory />}
            />
            {/*  <Route path="warehouse-inventory">
            <Route
              element={
                <InnerPageLayout
                  name="Warehouse Inventory"
                  description="Manage and organize your Warehouse inventory"
                  icon={<BucketIcon />}
                />
              }
            >
              <Route
                element={
                  <SalesAndInvoicingWrapper Nav={<WarehouseInventoryNav />} />
                }
              >
                <Route index element={<Navigate to="inventory-list" />} />
                <Route path="inventory-list" element={<InventoryList />} />
                <Route path="item-issuer" element={<Test />} />
                <Route path="branches-inventory" element={<Test />} />
                <Route path="inventory-manager" element={<Test />} />
                <Route path="vendor" element={<Test />} />
              </Route>
            </Route>
          </Route> */}

            <Route path="maintenance">
              <Route index element={<ChartOfAccount />} />
              <Route path="chart-of-account" element={<ChartOfAccount />} />
              <Route path="account-list" element={<Account />} />
            </Route>

            {/*  */}
            <Route basename path="reconcilation">
              <Route
                element={
                  <InnerPageLayout
                    Nav={<ReconcilationNav />}
                    name="Reconcilation"
                    description=""
                    icon={<PageHeaderListIcon />}
                  />
                }
              >
                {/* Rerender WHEN id CHANGES */}
                <Route
                  path="customer-ledger/:PaymentType"
                  element={
                    <CustomerLedgerReconcilation key={location.pathname} />
                  }
                />
                <Route
                  path="bank"
                  element={<BankAccountAnalysis hasReconcilation={true} />}
                />
                <Route
                  path="expenses"
                  element={<Expenses hasReconcilation={true} />}
                />
              </Route>
            </Route>

            {/* */}
            <Route basename path="drums">
              <Route
                element={
                  <InnerPageLayout
                    name="Drums Account"
                    description=""
                    icon={<PageHeaderListIcon />}
                    Nav={<DrumsNav />}
                  />
                }
              >
                <Route index element={<Navigate to="update-drum-account" />} />
                <Route
                  path="update-drum-account"
                  element={<PostDrumPayment />}
                />
                <Route
                  path="drum-account-sheet"
                  element={
                    <CustomerLedger usage="drums" key={location.pathname} />
                  }
                />
              </Route>
            </Route>
          </Route>
          {/*  */}

          {/*  */}

          <Route path="/no-hr" element={<NOHR />} />
          {/*  */}
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </div>
    </Suspense>
  );
}

export default App;
