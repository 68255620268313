import { Dropdown, Form, Button, Table } from "react-bootstrap";
import { useEffect, useState } from "react";
import MagnifyIcon from "mdi-react/MagnifyIcon";
import { useQuery } from "react-query";
import queryString from "query-string";
// import { toast } from "react-toastify";
import { Link } from "react-router-dom";

import "./../../assets/scss/scoped/invoicelist.scoped.scss";
import useDebounce, { useQueryParams } from "../../utils/hooks";
import CachedIcon from "mdi-react/CachedIcon";
// import { queryActions } from "../../utils/reactQueryActions";
import { paginationOptions } from "../../utils/helpers";
import { useAuth } from "../../hooks/useAuth";
import ReactPaginate from "react-paginate";
import DotsVerticalIcon from "mdi-react/DotsVerticalIcon";
import NewVendorModal from "../NewVendorModal";
import EditVendorModal from "./EditVendor";

export default function Vendors() {
  const { backendUrl, token } = useAuth();
  const [showCreateNewCustomerModal, setShowCreateNewCustomerModal] = useState(
    false
  );
  const [showEditVendorModal, setShowEditVendorModal] = useState({
    open: false,
    id: "",
  });

  const initialFilterParams = { q: "", page: 1, limit: 40 };

  const [queryParams, setQueryParams] = useQueryParams({
    ...initialFilterParams,
  });
  const [filterParams, setFilterParams] = useState({
    ...initialFilterParams,
    ...queryParams,
  });
  const debouncedFilterParams = useDebounce(filterParams, 500);

  useEffect(() => {
    setQueryParams({ ...queryParams, ...debouncedFilterParams });
  }, [debouncedFilterParams]);

  const handleSearchQueryChange = (e) => {
    setFilterParams({
      ...filterParams,
      [e.target.name]: e.target.value,
    });
  };

  const getCustomer = async (debouncedqueryParams) => {
    let response = await fetch(
      `${backendUrl}/api/vendors?${queryString.stringify(
        debouncedqueryParams
      )}`,
      {
        method: "GET",
        headers: {
          Accept: "Application/json",
          "Content-Type": "Application/json",
          Authorization: `Bearer ${token}`,
        },
        credentials: "include",
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const { data } = await response.json();
    return data;
  };

  const { data = { vendors: [] }, refetch } = useQuery(
    ["GET all the vendors", debouncedFilterParams],
    () => getCustomer(debouncedFilterParams),
    {
      enabled: true,
      keepPreviousData: true,
    }
  );

  const setSelectedVendor = (e) => {
    if (Object.entries(e).length > 0) {
      refetch();
    }
  };

  const setEditVendor = (e) => {
    if (e) {
      refetch();
    }
  };

  return (
    <section className="cashbook-wrapped">
      <main className="bg-white">
        <header className="p-4">
          <div className="search-area d-flex flex-wrap justify-content-between gap-3 w-100">
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-wrap gap-3">
                <div className="global-search-area">
                  <MagnifyIcon />
                  <Form.Control
                    id="queryParams-q"
                    className=""
                    name="q"
                    value={filterParams.LastName}
                    onChange={(e) => handleSearchQueryChange(e)}
                    placeholder="Search..."
                  />
                </div>
                <Button
                  onClick={() => refetch()}
                  variant=""
                  className="bg-light-blue border-0 text-primary"
                >
                  <CachedIcon />
                </Button>
              </div>
            </div>
            <Button
              variant="primary"
              onClick={() => setShowCreateNewCustomerModal(true)}
            >
              + Create New
            </Button>
          </div>
        </header>

        <div className="content px-4 pb-4">
          <Table borderless striped responsive className="product-table">
            <thead className="sticky border-bottom">
              <tr>
                <th />
                <th>Company Name</th>
                <th>Contact Name</th>
                <th>Contact Title</th>
                <th>Date Created</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Vendor_ID</th>
                <th>City</th>
                <th>Address</th>
              </tr>
            </thead>

            <tbody className="blue-hover">
              {data.vendors &&
                data.vendors.map((el, index) => (
                  <tr key={index}>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant=""
                          className="bg-white border-0"
                          bsPrefix="print more"
                        >
                          <DotsVerticalIcon />
                        </Dropdown.Toggle>
                        <Dropdown.Menu
                          popperConfig={{
                            strategy: "fixed",
                          }}
                          renderOnMount
                          className="dropdown-with-icons"
                        >
                          <Dropdown.Item
                            onClick={() =>
                              setShowEditVendorModal({
                                open: true,
                                id: el.Vendor_ID,
                              })
                            }
                            as="button"
                          >
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <Link
                              to={`/reports/purchase-history?vendorNumber=${
                                el.Vendor_ID
                              }&NoDate=${true}&CompName=${el.CompanyName}`}
                            >
                              Vendor Supply History
                            </Link>
                          </Dropdown.Item>
                          <Dropdown.Item>
                            <Link
                              to={`/vendor/vendors-ledger?customerId=${
                                el.Vendor_ID
                              }&NoDate=${true}&customerName=${el.CompanyName}`}
                            >
                              View Vendor Ledger
                            </Link>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                    <td>{el.CompanyName}</td>
                    <td>{el.ContactName}</td>
                    <td>{el.ContactTitle}</td>
                    <td>{el.DateCreated}</td>
                    <td>{el.Email}</td>
                    <td>{el.Phone}</td>
                    <td>{el.Vendor_ID}</td>
                    <td>{el.City}</td>
                    <td>{el.Address}</td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </div>

        <div className="d-flex justify-content-between px-4 align-items-center pagination pb-4 ">
          <div className="pagination_left d-flex gap-3 align-items-center">
            <p className="m-0 p-0">Show</p>
            <select
              value={queryParams.limit}
              name="limit"
              className="form-select "
              onChange={(e) => handleSearchQueryChange(e)}
            >
              <option value="10">10 rows</option>
              <option value="20">20 rows</option>
              <option value="30">30 rows</option>
              <option value="40">40 rows</option>
            </select>
          </div>

          <ReactPaginate
            {...paginationOptions}
            pageCount={Math.ceil(data.count / queryParams.limit)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={0}
            onPageChange={({ selected }) => {
              document.body.scrollTop = document.documentElement.scrollTop = 0;
              setQueryParams({
                ...queryParams,
                page: selected + 1,
              });
            }}
            forcePage={queryParams.page - 1}
          />
        </div>

        {showCreateNewCustomerModal && (
          <NewVendorModal
            showCreateNewVendorModal={showCreateNewCustomerModal}
            setShowCreateNewVendorModal={setShowCreateNewCustomerModal}
            setSelectedVendor={setSelectedVendor}
          />
        )}
        {showEditVendorModal && (
          <EditVendorModal
            setShowCreateNewVendorModal={setShowEditVendorModal}
            showCreateNewVendorModal={showEditVendorModal}
            setSelectedVendor={setEditVendor}
          />
        )}
      </main>
    </section>
  );
}
