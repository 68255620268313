import { useStoreState } from "easy-peasy";
import { cloneDeep, isEqual, lowerCase, uniqBy, uniqWith } from "lodash";
import { isEmpty } from "lodash";
import { useMemo } from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { Badge, Button, Form } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import {
  useLocation,
  useNavigate,
  useNavigationType,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { toast } from "react-toastify";
import ConfirmDialog from "../components/ConfirmDialogue";
import { backendApis } from "../config";
import { useAuth } from "../hooks/useAuth";
import { searchParamsToObject } from "./helpers";

// Hook
export function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  );
}

export const useLocalStorage = (keyName, defaultValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const value = window.localStorage.getItem(keyName);

      if (value) {
        return JSON.parse(value);
      } else {
        window.localStorage.setItem(keyName, JSON.stringify(defaultValue));
        return defaultValue;
      }
    } catch (err) {
      return defaultValue;
    }
  });

  const setValue = (newValue) => {
    try {
      window.localStorage.setItem(keyName, JSON.stringify(newValue));
    } catch (err) {}
    setStoredValue(newValue);
  };

  return [storedValue, setValue];
};

export function usePrevious(value) {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();
  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

export function useAsyncReference(value, isProp = false) {
  const ref = useRef(value);
  const [, forceRender] = useState(false);

  function updateState(newState) {
    if (!Object.is(ref.current, newState)) {
      ref.current = newState;
      forceRender((s) => !s);
    }
  }

  if (isProp) {
    ref.current = value;
    return ref;
  }

  return [ref.current, updateState];
}

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

export function ScrollOnLocationChange() {
  let location = useLocation();
  let { id } = useParams();

  useEffect(() => {
    document.documentElement.style.scrollBehavior = "auto";
    setTimeout(() => window.scrollTo(0, 0), 0);
    setTimeout(
      () => (document.documentElement.style.scrollBehavior = "smooth"),
      0
    );
  }, [location]);

  return null;
}

export function ScrollOnParamsChange(params) {
  useEffect(() => {
    document.documentElement.style.scrollBehavior = "auto";
    setTimeout(() => window.scrollTo(0, 0), 0);
    setTimeout(
      () => (document.documentElement.style.scrollBehavior = "smooth"),
      0
    );
  }, [params]);

  return null;
}

export function useFirstRender() {
  const firstRender = useRef(true);

  useEffect(() => {
    firstRender.current = false;
  }, []);

  return firstRender.current;
}

export function useQueryParams(
  defaultParams = {
    page: 1,
    limit: 40,
  }
) {
  function searchParamsToObject(entries) {
    const result = {};
    for (const [key, value] of entries) {
      if (key === "switchCompany") continue;
      result[key] = value;
    }

    return result;
  }

  let url = new URL(document.location.href);
  url = searchParamsToObject(url.searchParams.entries());
  let requiredQueryParams = defaultParams;

  const initialQueryParams = !isEmpty(url)
    ? { ...requiredQueryParams, ...url }
    : requiredQueryParams;

  const [searchQuery, setSearchQuery] = useSearchParams(initialQueryParams);
  const [queryParams, setQueryParams] = useState(initialQueryParams);
  const debouncedQueryParams = useDebounce(queryParams, 500);

  useEffect(() => {
    setSearchQuery(debouncedQueryParams, { replace: true });
  }, [debouncedQueryParams]);

  return [queryParams, setQueryParams];
}

export default useDebounce;

// listen history change and store it
export function useHistoryStack() {
  const [stack, setStack] = useState([]);
  const { pathname } = useLocation();
  const type = useNavigationType();
  useEffect(() => {
    if (type === "POP") {
      setStack(stack.slice(0, stack.length - 1));
    } else if (type === "PUSH") {
      setStack([...stack, pathname]);
    } else {
      setStack([...stack.slice(0, stack.length - 1), pathname]);
    }
  }, [pathname, type]);

  return stack;
}

export function useCanGoBack() {
  const navigate = useNavigate();
  const historyStack = useHistoryStack();

  const goBack = (to, options = {}) => {
    historyStack.length > 1 ? navigate(-1) : navigate(to, options);
  };
  return goBack;
}

export function useScrollTop() {
  useEffect(() => {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    // document.documentElement.scrollTop = 0;
    console.log("scroll top");
  }, []);

  return null;
}

export const useToggle = (initialState = false) => {
  // Initialize the state
  const [state, setState] = useState(initialState);

  // Define and memorize toggler function in case we pass down the component,
  // This function change the boolean value to it's opposite value
  const toggle = useCallback(() => setState((state) => !state), []);

  return [state, setState, toggle];
};

function useIsFirstRender() {
  const isFirst = useRef(true);

  if (isFirst.current) {
    isFirst.current = false;

    return true;
  }

  return isFirst.current;
}

export function useUpdateEffect(effect, deps) {
  const isFirst = useIsFirstRender();

  useEffect(() => {
    if (!isFirst) {
      return effect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}

export function useEffectOnce(effect) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(effect, []);
}

export function useSingleAndDoubleClick(
  actionSimpleClick,
  actionDoubleClick,
  payload = {},
  delay = 250
) {
  const [click, setClick] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      // simple click
      if (click === 1) actionSimpleClick(payload);
      setClick(0);
    }, delay);

    // the duration between this click and the previous one
    // is less than the value of delay = double-click
    if (click === 2) actionDoubleClick(payload);

    return () => clearTimeout(timer);
  }, [click]);

  return () => setClick((prev) => prev + 1);
}

export function useBackendUrl() {
  const [user] = useLocalStorage("user", null);
  const backendUrl = useMemo(() => {
    const api = backendApis.find((el) => el.name === user?.company);
    return api?.url;
  }, [user]);
  return backendUrl;
}

export function useToken() {
  const [user] = useLocalStorage("user", null);
  return user?.token;
}

export function useIsAdmin() {
  const [user] = useLocalStorage("user", null);
  return lowerCase(user?.Department) === "admin";
}

export function useIsSales() {
  const [user] = useLocalStorage("user", null);
  return ["sales", "sales manager"].includes(lowerCase(user?.Department));
}

export function useIsSalesManager() {
  const [user] = useLocalStorage("user", null);
  return ["sales manager"].includes(lowerCase(user?.Department));
}

export function useIsStore() {
  const [user] = useLocalStorage("user", null);
  return lowerCase(user?.Department) === "store";
}

export function useIsCashier() {
  const [user] = useLocalStorage("user", null);
  return (
    lowerCase(user?.Department) === "cashier" ||
    lowerCase(user?.Department) === "accountant"
  );
}

export function useIsInViewport(ref) {
  const options = {
    threshold: 1,
    rootMargin: "0px",
    root: null,
  };

  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(
        ([entry]) => setIsIntersecting(entry.isIntersecting),
        options
      ),
    []
  );

  useEffect(() => {
    ref && observer.observe(ref);

    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}

export function useChosenBackendUrl() {
  const { backendUrl } = useAuth();
  const url = useStoreState((state) =>
    state.selectedCompanyForAnalytics?.url
      ? state.selectedCompanyForAnalytics.url
      : backendUrl
  );

  return url;
}

export function useReconcilation({ type, refetch }) {
  const { backendUrl } = useAuth();
  const [reconciledList, setReconciled] = useState([]);

  //const postReconciledList = () => {};

  const isReconciled = ({ TransactionID, ...rowData }) => {
    let foundItem;
    if (type === "customerLedger" || type === "bank") {
      foundItem = reconciledList.find(
        (el) =>
          el.TransactionID === TransactionID &&
          el.Post_Time === rowData.Post_Time &&
          el.BankName === rowData.BankName
      );
    } else {
      foundItem = reconciledList.find(
        (el) => el.TransactionID === TransactionID
      );
    }
    return foundItem ? Boolean(foundItem?.checked) : false;
  };

  const setUpReconcilations = (data) => {
    const existingData = reconciledList;

    let newItems = data.map((el) => ({
      TransactionID: el.Trans_ID,
      ...el,
      checked: el.reconciled,
      type,
    }));

    newItems = newItems.filter((el) => {
      const found = existingData.find((old) => {
        if (type === "customerLedger" || type === "bank") {
          return (
            old.TransactionID === el.TransactionID &&
            old.Post_Time === el.Post_Time &&
            old.BankName === el.BankName
          );
        } else {
          return old.TransactionID === el.TransactionID;
        }
      });

      return !found;
    });

    setReconciled((oldData) => cloneDeep([...oldData, ...newItems]));
  };

  const addToReconciledList = ({ TransactionID, checked, ...rowData }) => {
    setReconciled((oldData) => {
      let foundIndex;

      if (type === "customerLedger" || type === "bank") {
        foundIndex = oldData.findIndex(
          (el) =>
            el.TransactionID === TransactionID &&
            el.Post_Time === rowData.Post_Time &&
            el.BankName === rowData.BankName
        );
      } else {
        foundIndex = oldData.findIndex(
          (el) => el.TransactionID === TransactionID
        );
      }

      if (foundIndex !== -1) {
        oldData[foundIndex] = {
          TransactionID,
          checked,
          ...rowData,
        };
      } else {
        oldData = [
          ...oldData,
          {
            TransactionID,
            checked,
            ...rowData,
          },
        ];
      }

      return cloneDeep([...oldData]);
    });
  };

  const postReconciledApi = async (payload) => {
    const formData = new FormData();
    formData.append("payload", JSON.stringify(payload));

    let response = await fetch(
      `${backendUrl}/api/transaction/post-reconcilations`,
      {
        method: "POST",
        credentials: "include",
        body: formData,
      }
    );
    if (!response.ok) {
      response = await response.json();
      throw new Error(response.message);
    }
    const res = await response.json();
    return res;
  };

  const postMutation = useMutation((payload) => postReconciledApi(payload), {
    onSuccess: ({ data, message }) => {
      toast.success(message);
      if (refetch) refetch();
    },
    onError: ({ message = "" }) => {
      toast.error(`Unable to perform action: ${message}`);
    },
  });

  const postReconciled = async () => {
    if (
      await ConfirmDialog({
        title: "Post Reconcilation",
        description: "Are you sure you want to post",
      })
    ) {
      postMutation.mutate({
        reconcilations: reconciledList.map((el) => ({
          ...el,
          reconciled: el.checked,
          type,
        })),
      });
    }
  };

  const checkAll = (checked) => {
    setReconciled((oldData) =>
      cloneDeep([...oldData.map((el) => ({ ...el, checked }))])
    );
  };

  const PostReconciledBtn = () => {
    return !isEmpty(reconciledList) ? (
      <>
        <Form.Check
          type={"checkbox"}
          checked={reconciledList.every((el) => el.checked)}
          label="Select All"
          onChange={(e) => checkAll(e.target.checked)}
        />

        <Button onClick={() => postReconciled()} variant="primary">
          Post Reconcilations{" "}
          {/* <Badge pill bg="primary">
          {reconciledList.filter((el) => el.checked).length}
        </Badge> */}
        </Button>
      </>
    ) : (
      <Button variant="primary">Post Reconcilations</Button>
    );
  };

  return {
    reconciledList,
    addToReconciledList,
    isReconciled,
    PostReconciledBtn,
    setUpReconcilations,
    postReconcilationMutation: postMutation,
  };
}

// Hook
export function useMemoCompare(next, compare) {
  // Ref for storing previous value
  const previousRef = useRef();
  const previous = previousRef.current;
  // Pass previous and next value to compare function
  // to determine whether to consider them equal.
  const isEqual = compare(previous, next);
  // If not equal update previousRef to next value.
  // We only update if not equal so that this hook continues to return
  // the same old value if compare keeps returning true.
  useEffect(() => {
    if (!isEqual) {
      previousRef.current = next;
    }
  });
  // Finally, if equal then return the previous value
  return isEqual ? previous : next;
}
