import { isEmpty, lowerCase } from "lodash";
import ChevronLeftIcon from "mdi-react/ChevronLeftIcon";
import ChevronRightIcon from "mdi-react/ChevronRightIcon";
import currency from "currency.js";
import { toast } from "react-toastify";
import { customAlphabet } from "nanoid";
import moment, { isMoment } from "moment";
import { format } from "date-fns";
import { appSettings } from "../config";

export function activeRowStyle() {
  return {
    backgroundColor: "#2463AE",
    color: "#fff",
  };
}

export function waitFor(time) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, time);
  });
}

export const paginationOptions = {
  previousLabel: <ChevronLeftIcon />,
  nextLabel: <ChevronRightIcon />,
  pageClassName: "page-item",
  pageLinkClassName: "page-link",
  previousClassName: "page-item",
  previousLinkClassName: "page-link",
  nextClassName: "page-item next",
  nextLinkClassName: "page-link",
  breakLabel: "...",
  breakClassName: "page-item border-none",
  breakLinkClassName: "page-link",
  containerClassName: "pagination",
  activeClassName: "active",
  hrefBuilder: (pageIndex) => {
    let url = new URL(document.location.href);
    let params = new URLSearchParams(url.search);

    params.set("page", pageIndex);
    url.search = params.toString();

    return url.toString();
  },
};

export function searchParamsToObject(entries) {
  const result = {};
  for (const [key, value] of entries) {
    if (key === "switchCompany") continue;
    // if (!value) continue;
    result[key] = value;
  }

  return result;
}

export function isInventoryOrFundRequest(requisitionType) {
  return ["inventory", "fund request"].includes(lowerCase(requisitionType));
}

export function currencyJs(args) {
  return currency(...args);
}

export function resolveApprovalBadgeBg(status) {
  return lowerCase(status) === "pending"
    ? "warning"
    : lowerCase(status) === "disapproved"
    ? "danger"
    : "success";
}

export const maxTopPopperConfig = ({ maxTop = -210 }) => {
  return {
    name: "maxTopPopperConfig",
    enabled: true,
    phase: "beforeWrite",
    fn({ state }) {
      const oldTransfrom = String(state.styles.popper?.transform);
      const y = Number(
        String(oldTransfrom)
          .split(",")[1]
          .split(")")[0]
          .split("px")[0]
          .trim()
      );

      const x = Number(
        String(oldTransfrom)
          .split(",")[0]
          .split("(")[1]
          .split("px")[0]
          .trim()
      );

      state.styles.popper.transform =
        y < maxTop
          ? `translate(${x}px, ${maxTop}px)`
          : `translate(${x}px, ${y}px)`;
    },
  };
};

export function customerFullName(customer) {
  return `${customer?.LastName || ""} ${customer?.FirstName ||
    ""} ${customer?.MiddleName || ""} `;
}

export function employeeFullName(employee) {
  return `${employee?.LastName} ${employee?.FirstName}  ${
    employee?.MiddleName
  }`;
}

export function defaultSelectValue(
  value,
  options = [],
  defaultOption = { value: "", label: "All" }
) {
  return value && options
    ? options.find((el) => el.value === value)
    : defaultOption;
}

export function copyText(text) {
  const textarea = document.createElement("textarea");
  textarea.textContent = text;
  textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
  document.body.appendChild(textarea);
  textarea.select();
  textarea.setSelectionRange(0, 99999);
  document.execCommand("copy");
  document.body.removeChild(textarea);
  toast.success("Copied");
}

export const styleHelper = {
  innerHeight: window.innerHeight,
  innerWidth: window.innerWidth,
  isMobile: window.matchMedia("(max-width: 960px )").matches,
};

export const reactSelectTheme = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    //  primary25: "#E2E8F0",
    primary: "#2463AE",
  },
});

export const qtyFormatToString = (qty, roundPieces = false) => {
  // console.log(qty);
  const value = String(qty);
  const splitValue = value.split("-");

  const plural = (value) => {
    return Number(value) > 1 ? "s" : "";
  };

  if (splitValue?.length > 1) {
    const tons = splitValue[0];
    let pieces = splitValue[1];
    const splitType = splitValue[2];

    if (roundPieces && pieces) {
      pieces = parseInt(pieces);
    }

    const tonsText = Number(tons) > 0 ? `${tons} ton${plural(tons)}` : "";
    const piecesText =
      Number(pieces) > 0
        ? `${pieces} ${splitType === "normal" ? "" : "pieces"}`
        : `${splitType === "normal" ? "0" : ""}`;

    return `${tonsText} ${piecesText}`;
  } else {
    const tons = splitValue[0];
    return `${tons} ton${plural(tons)}`;
  }
};

// qty to tons and pcs eg 1.5 1 ton 5 pcs
export const qtyFormat = (qty, measurement, itemMeasurements = []) => {
  try {
    // console.log(qty, isEmpty(measurement));
    if (isEmpty(measurement)) return `0-${qty}-normal`;

    const foundMeasurement = itemMeasurements.find(
      (el) => el.Size === measurement
    );

    if (isEmpty(foundMeasurement)) return `0-${qty}-normal`;

    let base = foundMeasurement?.Quantity ? foundMeasurement.Quantity : 13;

    /* switch (measurement) {
      case "8 mm":
        base = 210;
        break;
      case "10 mm":
        base = 135;
        break;
      case "12 mm":
        base = 94;
        break;
      case "16 mm":
        base = 53;
        break;
      case "20 mm":
        base = 34;
        break;
      case "25 mm":
        base = 22;
        break;
      case "32 mm":
        base = 13;
        break;
      default:
        base = 13;
      // code block
    } */

    const value = currency(qty, { symbol: "", separator: "" })
      .divide(base)
      .format();

    // console.log(value);

    const tons = Math.trunc(Number(value));
    const pcs = qty % base;
    //  console.log(qty, base, tons, pcs);
    return `${tons}-${pcs}`;
  } catch (err) {
    console.log(err);
    return 0;
  }
};

export const tonsToPcs = (tons, measurement, itemMeasurements = []) => {
  try {
    const foundMeasurement = itemMeasurements.find(
      (el) => el.Size === measurement
    );

    let base = foundMeasurement?.Quantity;
    if (isEmpty(foundMeasurement)) base = 1;

    /* switch (measurement) {
      case "8 mm":
        base = 210;
        break;
      case "10 mm":
        base = 135;
        break;
      case "12 mm":
        base = 94;
        break;
      case "16 mm":
        base = 53;
        break;
      case "20 mm":
        base = 34;
        break;
      case "25 mm":
        base = 22;
        break;
      case "32 mm":
        base = 13;
        break;
      default:
      // code block
    } */

    const value = currency(tons, { symbol: "", separator: "" })
      .multiply(base)
      .format();

    return Number(value);
  } catch (err) {
    console.log(err);
    return 0;
  }
};

export const pcsToTons = (pcs, measurement, itemMeasurements = []) => {
  try {
    const foundMeasurement = itemMeasurements.find(
      (el) => el.Size === measurement
    );
    let base = foundMeasurement.Quantity;
    if (isEmpty(foundMeasurement)) base = 1;

    const value = currency(pcs, { symbol: "", separator: "" })
      .divide(base)
      .format();

    // return parseInt(Number(value));
    return Number(value);
  } catch (err) {
    console.log(err);
    return 0;
  }
};

export const toTonsOrPcs = (qty, measurement, itemMeasurements = []) => {
  const foundMeasurement = itemMeasurements.find(
    (el) => el.Size === measurement
  );
  let base = foundMeasurement?.Quantity ? foundMeasurement.Quantity : 13;

  const value = currency(qty, { symbol: "", separator: "" })
    .divide(base)
    .format();

  const tons = Math.trunc(Number(value));
  const pcs = qty % base;
  //  console.log(qty, base, tons, pcs);
  return Number(tons) > 0 ? tons : pcs;
};

const alphabet =
  "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
const number = "0123456789";

export const nanoid = (max = 12, type = "alphabet") => {
  const nanoid = customAlphabet(type === "alphabet" ? alphabet : number, max);
  const str = nanoid();
  return str;
};

// export const nanoidNumber = () => {}

export function convertMomentDate(date) {
  return isMoment(date)
    ? date.format("MMM Do, YYYY")
    : moment(date).format("MMM Do, YYYY");
}

export function scrollToElement(
  selector,
  options = { block: "center", inline: "center", scroll: true }
) {
  setTimeout(() => {
    const el = document.querySelector(selector);
    if (el) {
      el.focus();
      if (el.select) el.select();
      if (options.scroll) el.scrollIntoView({ behavior: "smooth", ...options });
    }
  }, 50);
}

export const Units = [
  {
    label: "Tons",
    value: "Tons",
  },
  {
    label: "Pieces",
    value: "Pieces",
  },
  {
    label: "Each",
    value: "Each",
  },
  {
    label: "Carton",
    value: "Carton",
  },
  {
    label: "Bags",
    value: "Bags",
  },
  {
    label: "Litres",
    value: "Litres",
  },
  {
    label: "Centiliter",
    value: "Centiliter",
  },
  {
    label: "Drum",
    value: "Drum",
  },
  {
    label: "Bundle",
    value: "Bundle",
  },
  {
    label: "KG",
    value: "kG",
  },
  {
    label: "G",
    value: "G",
  },
  {
    label: "Roll",
    value: "Roll",
  },
  {
    label: "Packets",
    value: "Packets",
  },
  {
    label: "Cups",
    value: "Cups",
  },
  {
    label: "Rubber",
    value: "Rubber",
  },
  {
    label: "Can",
    value: "Can",
  },
  {
    label: "Sachet",
    value: "Sachet",
  },
  {
    label: "Portion",
    value: "Portion",
  },
  {
    label: "Bottle",
    value: "Bottle",
  },
  {
    label: "Bowl",
    value: "Bowl",
  },
  {
    label: "Tin",
    value: "Tin",
  },
];

// if items is tons - the user should only choose tons or pieces
export function unitsResolver(options, chosen) {
  const selectOptions = [...options];
  try {
    if (!chosen) return selectOptions;

    if (["tons", "pieces"].includes(lowerCase(chosen))) {
      return selectOptions.filter((el) =>
        ["tons", "pieces"].includes(lowerCase(el.value))
      );
    } else {
      return selectOptions.filter(
        (el) => !["tons", "pieces"].includes(lowerCase(el.value))
      );
    }
  } catch (err) {
    console.log(err);
    return selectOptions;
  }
}

export const fetchActionsUtil = async (url, method, token, payload) => {
  let response = await fetch(url, {
    method: method,
    credentials: "include",
    body: JSON.stringify(payload),
    headers: {
      Accept: "Application/json",
      "Content-Type": "Application/json",
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
  if (!response.ok) {
    response = await response.json();
    console.log(response.message);
    throw new Error(response.message);
  }
  const res = await response.json();
  // console.log(res);
  // const { data, message } = res;
  return res;
};

export const sendFormData = async (url, formData) => {
  let response = await fetch(url, {
    method: "POST",
    credentials: "include",
    body: formData,
  });

  if (!response.ok) {
    response = await response.json();
    throw new Error(response.message);
  }

  const { data } = await response.json();
  return data;
};

export const printers = [
  { label: "A4 Size Printer", value: "A4 Size Printer" },
  { label: "Thermal Printer", value: "Thermal Printer" },
];

export const getDataForEdit = ({ qty, desc, itemMeasurements = [] }) => {
  try {
    qty = qty <= 0 ? 0 : qty;
    const tonsAndPcs = qtyFormat(qty, desc, itemMeasurements);
    const Tons = tonsAndPcs.split("-")[0];
    const Pieces = tonsAndPcs.split("-")[1];
    const formatType = tonsAndPcs.split("-")[2];

    // console.log(tonsAndPcs, desc, qty);

    return {
      Tons,
      Pieces,
      formatType,
    };
  } catch (err) {
    console.log(err);
    return {};
  }
};

export const scrollToTop = () => {
  document.body.scrollTop = document.documentElement.scrollTop = 0;
};

export const damageStatusOptions = [
  {
    label: "All",
    value: "",
  },
  {
    label: "Active",
    value: "Active",
  },
  {
    label: "Replaced By Vendor",
    value: "Replaced By Vendor",
  },
  {
    label: "Credit Note from Vendor",
    value: "Credit Note from Vendor",
  },
  {
    label: "Quarantined",
    value: "Quarantined",
  },
];

export const allAcountDetailIDs = [
  {
    data: [
      "CURRENT ASSET",
      "CASH AND CASH EQUIVALENT",
      "NON-CURRENT ASSETS",
      "FIXED ASSET",
      "ACCOUNTS RECEIVABLE",
      "COST OF SALES",
      "EXPENSES",
      "ACCUMULATED DEPRECIATION",
    ],
    num1: 1000,
    num2: 1999,
  },
  {
    data: [
      "ACCOUNTS PAYABLE",
      "CURRENT LIABILITIES",
      "NON-CURRENT LIABILITY",
      "LONG-TERM LIABILITIES",
    ],
    num1: 5000,
    num2: 5999,
  },
  {
    data: ["OWNER'S EQUITY"],
    num1: 3000,
    num2: 3999,
  },
  {
    data: ["INCOME", "OTHER INCOME"],
    num1: 4000,
    num2: 4999,
  },
];

export const calculateAccounts = (type, credit, debit) => {
  const accountOne = [
    {
      data: [
        "CURRENT ASSET",
        "CASH AND CASH EQUIVALENT",
        "NON-CURRENT ASSETS",
        "FIXED ASSET",
        "ACCOUNTS RECEIVABLE",
        "COST OF SALES",
        "EXPENSES",
        "ACCUMULATED DEPRECIATION",
      ],
      action: parseInt(debit) - parseInt(credit),
    },
    {
      data: [
        "ACCOUNTS PAYABLE",
        "CURRENT LIABILITIES",
        "NON-CURRENT LIABILITY",
        "LONG-TERM LIABILITIES",
        "OWNER'S EQUITY",
        "INCOME",
      ],
      action: parseInt(credit) - parseInt(debit),
    },
  ];

  let answer = 0;

  accountOne.every((d) => {
    if (d.data.some((e) => e === type)) {
      answer = d.action;
      return false;
    } else {
      return true;
    }
  });

  return answer;
};

export const displayAccountType = (mainType, amount, type) => {
  const accountOne = [
    {
      data: [
        "CURRENT ASSET",
        "CASH AND CASH EQUIVALENT",
        "NON-CURRENT ASSETS",
        "FIXED ASSET",
        "ACCOUNTS RECEIVABLE",
        "COST OF SALES",
        "EXPENSES",
        "ACCUMULATED DEPRECIATION",
      ],
      action: type === "debit" ? amount : 0,
    },
    {
      data: [
        "ACCOUNTS PAYABLE",
        "CURRENT LIABILITIES",
        "NON-CURRENT LIABILITY",
        "LONG-TERM LIABILITIES",
        "OWNER'S EQUITY",
        "INCOME",
      ],
      action: type === "credit" ? amount : 0,
    },
  ];

  let answer = 0;

  accountOne.every((d) => {
    if (d.data.some((e) => e === mainType)) {
      answer = d.action;
      return false;
    } else {
      return true;
    }
  });

  return answer;
};

export const calculateAccountsTotal = (arrayData) => {
  const accountOne = [
    {
      data: [
        "CURRENT ASSET",
        "CASH AND CASH EQUIVALENT",
        "NON-CURRENT ASSETS",
        "FIXED ASSET",
        "ACCOUNTS RECEIVABLE",
        "COST OF SALES",
        "EXPENSES",
        "ACCUMULATED DEPRECIATION",
      ],
      action: "Debit",
    },
    {
      data: [
        "ACCOUNTS PAYABLE",
        "CURRENT LIABILITIES",
        "NON-CURRENT LIABILITY",
        "LONG-TERM LIABILITIES",
        "OWNER'S EQUITY",
        "INCOME",
      ],
      action: "Credit",
    },
  ];

  const allTotalAmount = {
    Credit: 0,
    Debit: 0,
  };

  arrayData.forEach((ele) => {
    accountOne.forEach((d) => {
      if (d.data.some((e) => e === ele[1]["key"])) {
        allTotalAmount[d.action] += parseFloat(ele[1]["totalAmount"]);
      }
    });
  });

  // console.log(allTotalAmount);
  return allTotalAmount;
};

export const returnAmountTotal = (arr, key) => {
  let answer = 0;
  arr?.every((d) => {
    if (d.key === key) {
      answer = d.totalAmount;
      return false;
    } else {
      return true;
    }
  });
  return answer;
};

// timezone is messing up the code
export function formatDate(date, content = "dd MMM, yyyy") {
  try {
    if (date) {
      const dateWithoutTimezone = new Date(date.slice(0, -1));
      return format(dateWithoutTimezone, content);
    } else {
      return "...";
    }
  } catch (err) {
    console.log(err);
    return date;
  }
}

export const getStockQuantityBefore = (text) => {
  try {
    if (text && text.includes("/")) {
      return text.split("/")[0];
    } else {
      return 0;
    }
  } catch (err) {
    console.log(err);
    return 0;
  }
};

export const getStockQuantityAfter = (text) => {
  try {
    if (text && text.includes("/")) {
      return text.split("/")[1];
    } else {
      return 0;
    }
  } catch (err) {
    console.log(err);
    return 0;
  }
};

export const formatCurrencyToNumber = (value) => {
  return currency(value, {
    symbol: "",
    separator: "",
  }).format();
};

export const returnRowError = (rowName, index) => {
  return `${rowName} in the ${index + 1}${
    index === 0 ? "st" : index === 1 ? "nd" : index === 2 ? "rd" : "th"
  } row or delete the row`;
};

export const defaultRequisitionTypes = [
  {
    label: "Inventory",
    value: "Inventory",
  },
  {
    label: "Fund Request",
    value: "Fund Request",
  },
  {
    label: "Normal",
    value: "Normal",
  },
  {
    label: "Other",
    value: "Other",
  },
];

export function addSaleItem({
  selectedItem,
  selectedCustomer,
  itemMeasurements,
}) {
  return new Promise(async (resolve, reject) => {
    try {
      const values = {
        Quantity: selectedItem.Quantity,
        UnitPrice: selectedItem.UnitPrice,
        PriceSold: selectedItem.UnitPrice,
        durationNumber: 0,
        durationValue: "Month",
        Warranty: false,
        Discount: 0,
        saleType: "Each",
        requirePermission: false,
        Size: !isEmpty(selectedItem?.sizes)
          ? selectedItem.sizes[0]?.Size_Color
          : "",
      };

      // Check Customer type to get correct unit price
      const transType = lowerCase(selectedCustomer?.TransType);
      selectedItem.UnitPrice =
        transType === "distributor"
          ? selectedItem.Vat_5
          : ["wholesaler", "wholeseler"].includes(transType)
          ? selectedItem.Tax
          : selectedItem.UnitPrice;

      selectedItem.quantityInStock = selectedItem.Quantity;

      const unitPriceInTons = selectedItem.UnitPrice;
      const unitPriceInPcs = selectedItem.Tax;

      if (Number(values.Quantity) > selectedItem?.quantityInStock) {
        return reject({
          errors: {
            Quantity: `Not enough item in stock`,
          },
        });
      }

      // check if they have enough sizes in stock
      if (values.Size && appSettings.hasSize) {
        const selectedSize = selectedItem.sizes.find(
          (el) => el.Size_Color === values.Size
        );
        if (Number(values.Quantity) > selectedSize.Quantity) {
          return reject(
            "Size",
            `Not enough item in stock for the selected Size - ${values.Size}`
          );
        }
      }

      const theUnitPrice =
        lowerCase(values.saleType) === "tons" ||
        lowerCase(values.saleType) !== "pieces"
          ? unitPriceInTons
          : unitPriceInPcs;

      values.Warrant_Duration = `${values.durationNumber} ${
        values.durationValue
      }`;

      values.PriceSold = currency(values.PriceSold, {
        symbol: "",
        separator: "",
      }).format();

      values.SubTotal = currency(values.PriceSold, {
        symbol: "",
        separator: "",
      })
        .multiply(
          lowerCase(values.saleType) === "tons"
            ? pcsToTons(
                values.Quantity,
                selectedItem.Item_Desc,
                itemMeasurements
              )
            : values.Quantity
        )
        .format();

      values.Discount = currency(values.Discount, {
        symbol: "",
        separator: "",
      })
        .multiply(
          lowerCase(values.saleType) === "tons"
            ? pcsToTons(
                values.Quantity,
                selectedItem.Item_Desc,
                itemMeasurements
              )
            : values.Quantity
        )
        .format();

      // Get Profit Based on batch we are picking from---------------------------------------------------
      const quantityToSell = values.Quantity;
      let remainder = quantityToSell;
      const updatedBatchDataToSave = [];
      let measurement = itemMeasurements.find(
        (measurement) => measurement.Size === selectedItem.Item_Desc
      );

      //  console.log(measurement);

      if (
        lowerCase(values.saleType) !== "tons" &&
        lowerCase(values.saleType) !== "pieces"
      ) {
        measurement = {
          Quantity: 1,
        };
      }

      if (lowerCase(values.saleType) === "tons" && !measurement) {
        return toast.error("This item is not sold in Tons");
      }

      const priceSoldPerUnit =
        lowerCase(values.saleType) === "tons"
          ? currency(values.PriceSold)
              .divide(measurement.Quantity)
              .format()
          : values.PriceSold;

      for (let [index, batch] of selectedItem.batches.entries()) {
        const pcsUnitCost = currency(batch.UnitCost, {
          symbol: "",
          separator: "",
        })
          .divide(measurement.Quantity)
          .format();

        //    console.log(pcsUnitCost, batch.UnitCost, measurement.Quantity);

        if (Number(batch.Quantity) >= Number(remainder)) {
          // means we are at the last
          updatedBatchDataToSave.push({
            unitCost: pcsUnitCost,
            quantity: remainder,
            totalUnitCost: currency(pcsUnitCost, {
              symbol: "",
              separator: "",
            })
              .multiply(remainder)
              .format(),
            totalUnitPrice: currency(priceSoldPerUnit, {
              symbol: "",
              separator: "",
            })
              .multiply(remainder)
              .format(),
            totalMargin: currency(selectedItem?.Margin || 0).multiply(remainder)
              .value,
            totalIncentive: currency(selectedItem?.Incentive || 0).multiply(
              remainder
            ).value,
          });

          if (lowerCase(values.saleType) === "pieces") {
            values.UnitCost = pcsUnitCost;
          }
          break;
        } else {
          updatedBatchDataToSave.push({
            unitCost: pcsUnitCost,
            quantity: batch.Quantity,
            totalUnitCost: currency(pcsUnitCost, {
              symbol: "",
              separator: "",
            })
              .multiply(batch.Quantity)
              .format(),
            totalUnitPrice: currency(priceSoldPerUnit, {
              symbol: "",
              separator: "",
            })
              .multiply(batch.Quantity)
              .format(),
            totalMargin: currency(selectedItem?.Margin || 0).multiply(
              batch.Quantity
            ).value,
            totalIncentive: currency(selectedItem?.Incentive || 0).multiply(
              batch.Quantity
            ).value,
          });

          remainder = Number(
            currency(remainder, {
              symbol: "",
              separator: "",
            })
              .subtract(batch.Quantity)
              .format()
          );
        }
      }
      const totalProfit = updatedBatchDataToSave
        .map((el) =>
          currency(el.totalUnitPrice)
            .subtract(el.totalUnitCost)
            .subtract(el.totalIncentive)
            .format()
        )
        .reduce(
          (a, b) =>
            currency(a, {
              precision: 2,
            }).add(b),
          0
        );
      values.Profit = totalProfit;

      //---------------------------------
      values.Total_Incentive = updatedBatchDataToSave
        .map((el) => el.totalIncentive)
        .reduce(
          (a, b) =>
            currency(a, {
              precision: 2,
            }).add(b),
          0
        );

      //  For Service Overwrite ----------------------------------------------------------
      if (lowerCase(selectedItem.Item_Type) === "service") {
        values.Profit = currency(priceSoldPerUnit)
          .subtract(selectedItem.UnitCost)
          .multiply(values.Quantity).value;
        values.Total_Incentive = currency(selectedItem?.Incentive).multiply(
          values.Quantity
        ).value;
      }

      resolve({
        ...selectedItem,
        Serial_Number: values?.Size ? values.Size : selectedItem.Item_Desc,
        ...values,
      });
    } catch (err) {
      console.log(err);
      reject(err);
    }
  });
}

export const analyticsOptions = [
  {
    label: "Last 1 Year",
    value: "year",
  },
  {
    label: "Last 7 Days",
    value: "7",
  },
  {
    label: "Last 28 Days",
    value: "28",
  },
  {
    label: "Last 90 Days",
    value: "90",
  },
];

export const analyticsOptionsVariant2 = [
  {
    label: "All Time",
    value: "all-time",
  },
  {
    label: "Last 7 Days",
    value: "7",
  },
  {
    label: "Last 28 Days",
    value: "28",
  },
  {
    label: "Last 90 Days",
    value: "90",
  },
];
export function round(value, step) {
  step || (step = 1.0);
  var inv = 1.0 / step;
  return Math.round(value * inv) / inv;
}

export const didYouKnowOptions = [
  {
    value: "Walk In",
    label: "Walk In",
  },
  {
    value: "Flyers",
    label: "Flyers",
  },
  {
    value: "Radio",
    label: "Radio",
  },
  {
    value: "Bill board",
    label: "Bill board",
  },
  {
    value: "Friends",
    label: "Friends",
  },
  {
    value: "Television",
    label: "Television",
  },
  {
    value: "Website",
    label: "Website",
  },
  {
    value: "Search Engine",
    label: "Search Engine (Google, Bing, Yahoo etc)",
  },
  {
    value: "Social Media",
    label: "Social Media",
  },
];

export const customerTypeOptions = [
  {
    label: "Walk In",
    value: "Walk In",
  },
  {
    label: "Wholesaler",
    value: "Wholesaler",
  },
  {
    label: "Distributor",
    value: "Distributor",
  },
];

export const initialGeneralSettings = {
  convertToTonsAndPcs: false,
  enableCreditLimit: false,
  enableCreditPaymentForWalkIn: true,
  pendingInvoiceType: "Proforma Invoice",
  hideDiscountColumn: false,
  addSignatureOnInvoice: false,
  addItemDescriptionOnInvoice: false,
  unitPriceTextOnInvoice: "Price Sold",
  isPurchasePriceRequired: false,
  presetItemValues: true,
  hideUnitPrice: false,
  hideDiscount: false,
  presetFinalSellingPrice: false,
  linkInvoiceToPayment: false,
  hasfreightVendor: false,
  expenseRequiresAdminApproval: false,
  linkPaymentToInvoice: false,
  enableDrumAccount: false,
  costPerDrum: 4000,
  drumAccountID: "",
  drumAccountDescription: "",
  hideUnitCost: false,
  balanceInExpenseFromAccount: false,
};
